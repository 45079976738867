import { EDAReportAdGroupsColumnHeader } from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { EDOOHReportLocationsColumnHeader } from '../../../../../models'
import { TGlobalTables } from '../../../models'

export const doohMetricHeaders: Array<TGlobalTables> = [
  EDOOHReportLocationsColumnHeader.Playouts,
  EDOOHReportLocationsColumnHeader.Impressions,
  EDOOHReportLocationsColumnHeader.Costs,
  EDOOHReportLocationsColumnHeader.Cpm,
  EDAReportAdGroupsColumnHeader.ShareOfVoice,
  EDAReportAdGroupsColumnHeader.WinRate,
  // ESPReportProductsColumnHeader.AvgRank,
]
