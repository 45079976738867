import { useEffect } from 'react'
import { EParamSortOrder, EOrgType } from '@unfoldrtech/portal-mic'

import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import {
  EChannelTypeGlobal,
  ESPReportCampaignsColumnHeader,
} from '../../../models/core'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'

const getDefaultSortByForAdvertiser = (
  channelType: EChannelTypeGlobal | undefined,
  omittedMetrics: string[]
) => {
  if (channelType === EChannelTypeGlobal.SponsoredProduct) {
    if (omittedMetrics.includes(ESPReportCampaignsColumnHeader.Revenue)) {
      return ESPReportCampaignsColumnHeader.Carts
    }

    return ESPReportCampaignsColumnHeader.Revenue
  }

  if (omittedMetrics.includes(ESPReportCampaignsColumnHeader.Impressions)) {
    return ESPReportCampaignsColumnHeader.Name
  }

  return ESPReportCampaignsColumnHeader.Impressions
}

export const useGetDefaultSortBy = ({
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
  orgType,
  channelType,
}: {
  sortBy?: string
  sortOrder?: string
  setSortBy: (newSortBy?: string) => void
  setSortOrder: (newSortOrder?: EParamSortOrder) => void
  orgType: EOrgType
  channelType?: EChannelTypeGlobal
}) => {
  const featureFlags = useFeatureFlags()
  const omittedMetrics = featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] || []

  useEffect(() => {
    // Reset the sortBy on channelType change
    setSortBy(undefined)
  }, [channelType])

  useEffect(() => {
    if (sortBy) return

    if (sortOrder !== EParamSortOrder.Desc) {
      setSortOrder(EParamSortOrder.Desc)
    }

    if (orgType === EOrgType.Advertiser) {
      const advertiserSortBy = getDefaultSortByForAdvertiser(
        channelType,
        omittedMetrics
      )
      setSortBy(advertiserSortBy)

      if (
        advertiserSortBy === ESPReportCampaignsColumnHeader.Name &&
        omittedMetrics.includes(ESPReportCampaignsColumnHeader.Impressions)
      ) {
        setSortOrder(EParamSortOrder.Asc)
      }
    } else if (orgType === EOrgType.Retailer) {
      setSortBy(ESPReportCampaignsColumnHeader.Costs)
    }
  }, [sortBy, sortOrder, orgType, channelType])
}
