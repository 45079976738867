import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingAdGroupsChartV3 from '../../../../hooks/use-get-advertiser-reporting-ad-groups-chart-v3'
import useGetAdvertiserReportingAdGroupsTableV3 from '../../../../hooks/use-get-advertiser-reporting-ad-groups-table-v3'
import useGetRetailerReportingAdGroupsChartV3 from '../../../../hooks/use-get-retailer-reporting-ad-groups-chart-v3'
import useGetRetailerReportingAdGroupsTableV3 from '../../../../hooks/use-get-retailer-reporting-ad-groups-table-v3'

export const getTableAndChartAdGroupsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingAdGroupsTableV3
    chartFn = useGetAdvertiserReportingAdGroupsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingAdGroupsTableV3
    chartFn = useGetRetailerReportingAdGroupsChartV3
  }

  return { tableFn, chartFn }
}
