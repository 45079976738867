import {
  EDAReportAdGroupsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { daMetricHeaders } from './da-metric-headers'

export const daProductMetricHeaders: Array<TGlobalTables> =
  daMetricHeaders.filter(
    (header) =>
      !(
        header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
        header === EDAReportAdGroupsColumnHeader.WinRate ||
        header === ESPReportProductsColumnHeader.AvgRank ||
        header === EDAReportAdGroupsColumnHeader.Frequency
      )
  )
