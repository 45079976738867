import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AppContext, EDatasetKey } from '../../../models'
import {
  TDatasetWhitelistKey,
  TGlobalMetricData,
  TGlobalMetrics,
} from '../models'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { isCurrency, isPercentage } from '../helpers'
import {
  metricTypeMap,
  genericMetricNames,
  spMetricNames,
  daMetricNames,
  doohMetricNames,
  daKeywordMetricNames,
  daProductMetricNames,
  spProductMetricNames,
  genericAdgroupSearchMetricNames,
  spKeywordMetricNames,
  daAudienceMetricNames,
  doohAudienceMetricNames,
  daAdgroupMetricNames,
} from './constants'

export const useGetDatasetKeyChartWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()
  const featureFlags = useFeatureFlags()

  const [appContext] = useContext(AppContext)

  const orgType = appContext.userOrg!.type

  const whitelist = {} as Record<TDatasetWhitelistKey, Array<TGlobalMetricData>>

  const getGlobalMetricData = ({
    metricName,
  }: {
    metricName: TGlobalMetrics
  }): TGlobalMetricData => {
    return {
      metricName,
      translatedMetricName: intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${metricName}`,
      }),
      metricNumberType: metricTypeMap[metricName].aggregationType,
      isCurrency: isCurrency(metricName),
      isPercentage: isPercentage(metricName),
    }
  }

  const getChartWhitelist = (metricNamesList: Array<TGlobalMetrics>) => {
    return metricNamesList.map((metricName) =>
      getGlobalMetricData({ metricName })
    )
  }

  whitelist[EDatasetKey.Advertiser] = getChartWhitelist([...genericMetricNames])

  whitelist[EDatasetKey.CampaignGeneric] = getChartWhitelist([
    ...genericMetricNames,
  ])
  whitelist[EDatasetKey.CampaignSp] = getChartWhitelist([...spMetricNames])
  whitelist[EDatasetKey.CampaignDa] = getChartWhitelist([...daMetricNames])
  whitelist[EDatasetKey.CampaignDooh] = getChartWhitelist([...doohMetricNames])

  whitelist[EDatasetKey.AdgroupGeneric] = getChartWhitelist([
    ...genericMetricNames,
  ])
  whitelist[EDatasetKey.AdgroupGenericCategory] = getChartWhitelist([
    ...genericMetricNames,
  ])
  whitelist[EDatasetKey.AdgroupGenericKeyword] = getChartWhitelist([
    ...genericAdgroupSearchMetricNames, // exception
  ])
  whitelist[EDatasetKey.AdgroupDaPagePosition] = getChartWhitelist([
    ...daAdgroupMetricNames, // exception
  ])
  whitelist[EDatasetKey.AdgroupDoohScreen] = getChartWhitelist([
    ...doohMetricNames,
  ])

  whitelist[EDatasetKey.ProductSp] = getChartWhitelist([
    ...spProductMetricNames, // exception
  ])
  whitelist[EDatasetKey.ProductDa] = getChartWhitelist([
    ...daProductMetricNames, // exception
  ])

  whitelist[EDatasetKey.AdgroupSp] = getChartWhitelist([...spMetricNames])
  whitelist[EDatasetKey.AdgroupDa] = getChartWhitelist([
    ...daAdgroupMetricNames, // exception
  ])
  whitelist[EDatasetKey.AdgroupDooh] = getChartWhitelist([...doohMetricNames])

  whitelist[EDatasetKey.PositionDa] = getChartWhitelist([...daMetricNames])
  whitelist[EDatasetKey.AudienceDa] = getChartWhitelist([
    ...daAudienceMetricNames, // exception
  ])
  whitelist[EDatasetKey.AudienceDooh] = getChartWhitelist([
    ...doohAudienceMetricNames,
    // exception
  ])

  whitelist[EDatasetKey.ProductSpAdgroup] = getChartWhitelist([
    ...spProductMetricNames, // exception
  ])
  whitelist[EDatasetKey.ProductDaAdgroup] = getChartWhitelist([
    ...daProductMetricNames, // exception
  ])
  whitelist[EDatasetKey.CategorySpAdgroup] = getChartWhitelist([
    ...spMetricNames,
  ])
  whitelist[EDatasetKey.CategoryDaAdgroup] = getChartWhitelist([
    ...daMetricNames,
  ])
  whitelist[EDatasetKey.KeywordSpAdgroup] = getChartWhitelist([
    ...spKeywordMetricNames, // exception
  ])
  whitelist[EDatasetKey.KeywordDaAdgroup] = getChartWhitelist([
    ...daKeywordMetricNames, // exception
  ])
  whitelist[EDatasetKey.PositionDaAdgroupPagePosition] = getChartWhitelist([
    ...daMetricNames,
  ])
  whitelist[EDatasetKey.PositionDaAdgroupCategory] = getChartWhitelist([
    ...daMetricNames,
  ])
  whitelist[EDatasetKey.PositionDaAdgroupKeyword] = getChartWhitelist([
    ...daMetricNames,
  ])
  whitelist[EDatasetKey.AudienceDaAdgroup] = getChartWhitelist([
    ...daAudienceMetricNames, // exception
  ])
  whitelist[EDatasetKey.AudienceDoohAdgroup] = getChartWhitelist([
    ...doohAudienceMetricNames, // exception
  ])
  whitelist[EDatasetKey.LocationDooh] = getChartWhitelist([...doohMetricNames])

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    whitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(
          column.metricName
        )
    )
  }

  return whitelist[key]
}
