import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconEyeLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#icon-eye-line_svg__a)">
      <path
        d="M16 4.5c6.977 0 12.782 4.958 14 11.5-1.216 6.542-7.023 11.5-14 11.5S3.218 22.542 2 16C3.216 9.458 9.023 4.5 16 4.5Zm0 20.444c2.64 0 5.2-.885 7.263-2.51A11.489 11.489 0 0 0 27.358 16a11.49 11.49 0 0 0-4.098-6.427A11.746 11.746 0 0 0 16 7.066c-2.638 0-5.197.884-7.26 2.507A11.49 11.49 0 0 0 4.642 16a11.489 11.489 0 0 0 4.095 6.434A11.746 11.746 0 0 0 16 24.944Zm0-3.194a5.86 5.86 0 0 1-4.117-1.684A5.714 5.714 0 0 1 10.177 16c0-1.525.613-2.988 1.706-4.066A5.86 5.86 0 0 1 16 10.25a5.86 5.86 0 0 1 4.117 1.684A5.714 5.714 0 0 1 21.823 16a5.714 5.714 0 0 1-1.706 4.066A5.86 5.86 0 0 1 16 21.75Zm0-2.556c.858 0 1.68-.336 2.288-.935.606-.6.947-1.412.947-2.259s-.34-1.66-.947-2.259A3.256 3.256 0 0 0 16 12.806c-.858 0-1.68.336-2.287.935A3.174 3.174 0 0 0 12.764 16c0 .847.34 1.66.947 2.259s1.43.935 2.288.935Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="icon-eye-line_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIconEyeLine
