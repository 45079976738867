import { PayloadAction } from '@reduxjs/toolkit'
import { TGenericCampaignsReportChartFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'
import { ETimeBucketType } from '../../../models/core'

const setAggregationTypeV3 = (
  state: TGenericCampaignsReportChartFilters,
  action: PayloadAction<{
    aggregationType: ETimeBucketType
  }>
) => {
  state.aggregationType = action.payload.aggregationType
}

export default setAggregationTypeV3
