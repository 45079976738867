import { EDAReportAdGroupsColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { daMetricNames } from './da-metric-names'

export const daKeywordMetricNames: Array<TGlobalMetrics> = daMetricNames.filter(
  (header) =>
    !(
      header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
      header === EDAReportAdGroupsColumnHeader.WinRate
    )
)
