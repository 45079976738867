import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDAAdGroupsPositionsReportChartFilters,
  TDAAdGroupsReportChartFilters,
  TDOOHAdGroupsReportChartFilters,
  TDOOHAdGroupsScreensReportChartFilters,
  TGenericAdGroupsCategoriesReportChartFilters,
  TGenericAdGroupsKeywordsReportChartFilters,
  TGenericAdGroupsReportChartFilters,
  TPPRetailerId,
  TSPAdGroupsReportChartFilters,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamInventoryTypeGlobal,
  ETimeBucketType,
  IReportingAdGroupsChartResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetRetailerReportingAdGroupsChartV3({
  retailerId,
  advertiserId,
  inventoryType,
  campaignId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  enabled = true,
  suspense = true,
}:
  | {
      enabled?: boolean
      suspense?: boolean
    } & (
      | TGenericAdGroupsReportChartFilters
      | TGenericAdGroupsCategoriesReportChartFilters
      | TGenericAdGroupsKeywordsReportChartFilters
      | TSPAdGroupsReportChartFilters
      | TDAAdGroupsReportChartFilters
      | TDAAdGroupsPositionsReportChartFilters
      | TDOOHAdGroupsReportChartFilters
      | TDOOHAdGroupsScreensReportChartFilters
    ) &
      TPPRetailerId) {
  const queryKeyName = `getRetailerReportingAdGroupsChart_${inventoryType}_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<IReportingAdGroupsChartResponse>,
    AxiosError<IError>
  >(
    [
      queryKeyName,
      { start: startDate, end: endDate, campaignId, inventoryType },
    ],
    () => {
      return coreAPIV3
        .retailerReporting()
        .getRetailerAdGroupsReportChartV3(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          campaignId,
          inventoryType as EParamInventoryTypeGlobal,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType
        )
    },
    { suspense, enabled }
  )
}
