import { EDAReportAdGroupsColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { spMetricNames } from './sp-metric-names'

export const spKeywordMetricNames: Array<TGlobalMetrics> = spMetricNames.filter(
  (header) =>
    !(
      header === EDAReportAdGroupsColumnHeader.AvgRank ||
      header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
      header === EDAReportAdGroupsColumnHeader.WinRate
    )
)
