import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext, CampaignContext } from '../../../../models/contexts'
import {
  selectReportingFilters,
  setIsReportingV3,
} from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import {
  EChannelTypeGlobal,
  EInventoryTypeGlobal,
  IDAReportAdGroupsPositionsTable,
  IDAReportAdGroupsTable,
  IDOOHReportAdGroupsScreensTable,
  IDOOHReportAdGroupsTable,
  IGenericReportAdGroupsCategoriesTable,
  IGenericReportAdGroupsKeywordsTable,
  IGenericReportAdGroupsTable,
  ISPReportAdGroupsTable,
} from '../../../../models/core'
import {
  convertChannelTypeToParam,
  convertEParamChannelTypeToEChannelTypeGlobal,
  convertEParamInventoryTypeToEInventoryTypeGlobal,
} from '../../../../utils/helpers'
import { sanitizeDataTestId } from '../../../../utils'
import { getAdGroupsEDatasetKey } from '../helpers/get-ad-groups-e-dataset-key'
import { TDatasetWhitelistKey } from '../../models'
import { ReportingAdGroupsChartWrapper } from './ReportingAdGroupsChartWrapper'
import { ReportingAdGroupsTableWrapper } from './ReportingAdGroupsTableWrapper'

type IReportAdGroupsTable =
  | IGenericReportAdGroupsTable
  | IGenericReportAdGroupsCategoriesTable
  | IGenericReportAdGroupsKeywordsTable
  | ISPReportAdGroupsTable
  | IDAReportAdGroupsTable
  | IDAReportAdGroupsPositionsTable
  | IDOOHReportAdGroupsTable
  | IDOOHReportAdGroupsScreensTable

export const ReportingAdGroupsTemplate = ({
  channelType: propChannelType,
  inventoryType: propInventoryType,
}: {
  channelType?: EChannelTypeGlobal
  inventoryType?: EInventoryTypeGlobal
}) => {
  const pageTitle = propInventoryType
    ? `reporting.header.ad.groups.${sanitizeDataTestId(propInventoryType)}`
    : 'reporting.header.ad.groups'

  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const {
    channelType: reportingFilterChannelType,
    inventoryType: reportingFilterInventoryType,
  } = useSelector(selectReportingFilters)
  const channelType =
    propChannelType ||
    convertEParamChannelTypeToEChannelTypeGlobal(
      reportingFilterChannelType ||
        convertChannelTypeToParam(campaign.channelType)
    )
  const inventoryType =
    propInventoryType ||
    convertEParamInventoryTypeToEInventoryTypeGlobal(
      reportingFilterInventoryType
    )
  dispatch(setIsReportingV3({ IsReportingV3: true }))

  const [tableData, setTableData] = useState<IReportAdGroupsTable>()

  const onTableDataChange = (adGroupsTableData: IReportAdGroupsTable) => {
    setTableData(adGroupsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getAdGroupsEDatasetKey(inventoryType, channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={
          <ReportingAdGroupsChartWrapper
            channelType={channelType}
            inventoryType={inventoryType}
          />
        }
        tableWrapper={
          <ReportingAdGroupsTableWrapper
            onTableDataChange={onTableDataChange}
            channelType={channelType}
            inventoryType={inventoryType}
          />
        }
        onExportData={onExportData}
      />
    </>
  )
}
