import React, { useRef, useState, useContext, useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { EInventoryType, EUserRole } from '@unfoldrtech/portal-mic'

import {
  CurrencyText,
  Container,
  IconEdit,
  IconButton,
  EllipsisContainer,
  StyledAntPopover,
} from '../../../Global'
import { isUserAuthorised } from '../../../../utils/userAuthorisation'
import { TableDataCellBidPricePopoverContent } from './TableDataCellBidPricePopoverContent'
import usePatchAdGroup from '../../../../hooks/usePatchAdGroup'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { getAdGroupTargetList } from '../../../../store/campaigns'
import {
  CampaignContext,
  EOrgType,
  AppContext,
  EStatus,
  TTranslationKey,
  AdGroupContext,
  IAdGroup,
} from '../../../../models'
import { useGetIsCurrentCampaignDirectDeal } from '../../hooks/use-get-is-current-campaign-direct-deal'

export const TableDataCellBidPrice = ({
  dataValue,
  floorPrice,
  dataId,
  orgType,
  status,
  setErrorUpdate,
  setShowUpdateErrorToast,
}: {
  dataValue?: number
  floorPrice?: number
  dataId: number
  orgType: EOrgType
  status: EStatus
  setErrorUpdate: (error: TTranslationKey) => void
  setShowUpdateErrorToast: (show: boolean) => void
}) => {
  const [open, setOpen] = useState(false)
  const [bidPrice, setBidPrice] = useState<number | undefined>(dataValue)

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()
  const isDirectDeal = useGetIsCurrentCampaignDirectDeal()

  const ref = useRef<HTMLDivElement>(null)

  const {
    mutate: mutateAdGroup,
    reset: resetMutateAdGroup,
    isLoading: isUpdating,
  } = usePatchAdGroup({
    advertiserId,
    retailerId,
    adGroupId: adGroup?.id,
  })

  const userRole = appContext.userClaims?.userRole ?? EUserRole.None
  const isMutateAllowed = isUserAuthorised(
    userRole,
    EUserRole.AdvertiserCampaigns
  )
  const showEditIcon =
    orgType === EOrgType.Advertiser && dataValue !== undefined && dataValue >= 0
  const isDisabled =
    !isMutateAllowed || isDirectDeal || status === EStatus.Disabled

  const handleKeywordBidPriceChange = (keywordId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.Keyword,
      keywordsList: adGroup.keywordsList.map((k) =>
        k.keywordId === keywordId ? { ...k, bidPrice: bid } : k
      ),
      positionsList: adGroup.positionsList,
    }

    return updatedAdGroup
  }

  const handlePositionBidPriceChange = (positionId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.PagePosition,
      positionsList: adGroup.positionsList?.map((p) =>
        p.id === positionId ? { ...p, bidPrice: bid } : p
      ),
    }

    return updatedAdGroup
  }

  const handleCategoryBidPriceChange = (categoryId: number, bid: number) => {
    const updatedAdGroup = {
      inventoryType: EInventoryType.Category,
      categoriesList: adGroup.categoriesList?.map((p) =>
        p.id === categoryId ? { ...p, bidPrice: bid } : p
      ),
      positionsList: adGroup.positionsList,
    }

    return updatedAdGroup
  }

  const handleBidPriceSubmit = (bid: number) => {
    let updatedAdGroupTargeting = {}
    const payload = {}

    if (adGroup.inventoryType === EInventoryType.Keyword) {
      updatedAdGroupTargeting = handleKeywordBidPriceChange(dataId, bid)
    } else if (adGroup.inventoryType === EInventoryType.Category) {
      updatedAdGroupTargeting = handleCategoryBidPriceChange(dataId, bid)
    } else if (adGroup.inventoryType === EInventoryType.PagePosition) {
      updatedAdGroupTargeting = handlePositionBidPriceChange(dataId, bid)
    }
    resetMutateAdGroup()
    setOpen(false)

    payload.targeting = getAdGroupTargetList(
      updatedAdGroupTargeting as IAdGroup
    )

    mutateAdGroup(
      { payload },
      {
        onSuccess: () => {
          setBidPrice(bid)
        },
        onError: (error) => {
          setShowUpdateErrorToast(true)
          setErrorUpdate(
            error?.response?.data.errorKey as unknown as TTranslationKey
          )
        },
      }
    )
  }

  useEffect(() => {
    if (dataValue !== undefined) {
      setBidPrice(dataValue)
    }
  }, [dataValue])

  return (
    <Container ref={ref}>
      <Container display="flex" maxWidth="var(--gutter-10x)">
        <EllipsisContainer flex="1">
          <CurrencyText value={Number(bidPrice)} />
        </EllipsisContainer>

        {showEditIcon && (
          <Container display="inline" marginLeft="var(--gutter-1x)">
            <StyledAntPopover
              destroyTooltipOnHide
              open={open}
              onOpenChange={(visible) => setOpen(visible)}
              showArrow={false}
              trigger="click"
              content={
                <TableDataCellBidPricePopoverContent
                  bid={bidPrice!}
                  floorPrice={floorPrice!}
                  pricingStrategy={campaign.pricingStrategy!}
                  handleBidPriceSubmit={handleBidPriceSubmit}
                  isUpdating={isUpdating}
                />
              }
            >
              {!isDisabled && (
                <IconButton
                  opacity={1}
                  onClick={() => setOpen(true)}
                  disabled={isDisabled || isUpdating}
                >
                  {isUpdating ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <IconEdit />
                  )}
                </IconButton>
              )}
            </StyledAntPopover>
          </Container>
        )}
      </Container>
    </Container>
  )
}
