import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingDAPositionsChartWrapper } from './ReportingDAPositionsChartWrapper'
import { ReportingDAPositionsTableWrapper } from './ReportingDAPositionsTableWrapper'

import {
  EDatasetKey,
  IDAReportPositionsTable,
  TReportExportType,
  TReportingFilters,
} from '../../../../models'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
} from '../../../../models/contexts'

import {
  setAdGroupIds,
  setCampaignIds,
  setIsReportingV3,
} from '../../../../store/reportingFilters'

import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDatasetWhitelistKey } from '../../models'

/**
 * One report template per entity
 * Ex: ReportCategoriesTemplate, ReportCampaignsTemplate, etc ...
 */
export const ReportingDAPositionsTemplate = () => {
  const pageTitle = 'reporting.header.positions'

  const dispatch = useDispatch()
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)
  dispatch(setIsReportingV3({ IsReportingV3: true }))

  const [tableData, setTableData] = useState<IDAReportPositionsTable>()

  const [reportingFiltersIsSetup, setReportingFiltersIsSetup] = useState(false)

  const onTableDataChange = (locationsTableData: IDAReportPositionsTable) => {
    setTableData(locationsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) || EDatasetKey.PositionDa,
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  useEffect(() => {
    dispatch(
      setCampaignIds({
        campaignIds: campaign?.id,
      })
    )
    dispatch(
      setAdGroupIds({
        adGroupIds: adGroup?.id,
      })
    )
    setReportingFiltersIsSetup(true)
  }, [campaign.id, adGroup.id])

  return (
    <>
      {reportingFiltersIsSetup && (
        <ReportPageTemplateLayout
          title={pageTitle}
          isExportDisabled={Boolean(!tableData?.rows.length)}
          chartWrapper={<ReportingDAPositionsChartWrapper />}
          tableWrapper={
            <ReportingDAPositionsTableWrapper
              onTableDataChange={onTableDataChange}
            />
          }
          onExportData={onExportData}
        />
      )}
    </>
  )
}
