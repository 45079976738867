import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDOOHLocationsReportChartFilters,
  TPPRetailerId,
} from '../models/types'
import {
  EReportingTimeWindow,
  IDOOHReportingLocationsChartResponse,
} from '../models'
import { ETimeBucketType } from '../models/core'

export default function useGetRetailerReportingDOOHLocationsChartV3({
  retailerId,
  advertiserId,
  campaignId,
  adGroupId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TDOOHLocationsReportChartFilters &
  TPPRetailerId) {
  const queryKeyName = `getRetailerReportingDOOHLocationsChart_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<IDOOHReportingLocationsChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    () => {
      return coreAPI
        .v2RetailerReporting()
        .getDOOHRetailerLocationsReportChart(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          campaignId,
          adGroupId,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType
        )
    },
    { suspense, enabled }
  )
}
