import { TGlobalTables } from '../../../models'
import {
  EDAReportAdGroupsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../../../models/core'

export const daMetricHeaders: Array<TGlobalTables> = [
  ESPReportProductsColumnHeader.Impressions,
  EDAReportAdGroupsColumnHeader.UniqueReach,
  EDAReportAdGroupsColumnHeader.Frequency,
  EDAReportAdGroupsColumnHeader.Clicks,
  EDAReportAdGroupsColumnHeader.Ctr,
  EDAReportAdGroupsColumnHeader.Costs,
  EDAReportAdGroupsColumnHeader.Cpm,
  EDAReportAdGroupsColumnHeader.ShareOfVoice,
  EDAReportAdGroupsColumnHeader.WinRate,
  // ESPReportProductsColumnHeader.AvgRank,
  ESPReportProductsColumnHeader.Carts,
  EGenericReportCampaignsColumnHeader.Purchases,
  EGenericReportCampaignsColumnHeader.Revenue,
  EGenericReportCampaignsColumnHeader.Roas,
  EDAReportAdGroupsColumnHeader.PvCarts,
  EDAReportAdGroupsColumnHeader.PvPurchases,
  EDAReportAdGroupsColumnHeader.PvRevenue,
]
