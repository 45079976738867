import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { IKeywordFloorPrice } from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId } from '../utils/helpers'

export default function useGetKeywordFloorPrices({
  advertiserId,
  retailerId,
  enabled = true,
  isSuspense = true,
}: {
  advertiserId: number
  retailerId: number
  enabled?: boolean
  isSuspense?: boolean
}) {
  return useQuery<AxiosResponse<IKeywordFloorPrice[]>, AxiosError<IError>>(
    [`getDefaultFloorPrices_${advertiserId}_${retailerId}`],
    () =>
      coreAPI
        .v2AdvertiserCampaigns()
        .getKeywordsFloorPrices(
          advertiserId,
          retailerId,
          generateCorrelationId()
        ),
    {
      enabled,
      suspense: isSuspense,
    }
  )
}
