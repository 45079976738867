import { EDAReportAdGroupsColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { daMetricHeaders } from './da-metric-headers'

export const daAudienceMetricHeaders: Array<TGlobalTables> =
  daMetricHeaders.filter(
    (header) =>
      !(
        header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
        header === EDAReportAdGroupsColumnHeader.WinRate
      )
  )
