import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.25 13.1a3.85 3.85 0 1 0 0-7.7 3.85 3.85 0 0 0 0 7.7Z"
      stroke="currentColor"
      strokeWidth={1.674}
      strokeMiterlimit={10}
    />
    <path
      d="m12.25 23-6.6-8.8A8.253 8.253 0 0 1 12.25 1a8.253 8.253 0 0 1 6.6 13.2l-6.6 8.8Z"
      stroke="currentColor"
      strokeWidth={1.674}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgIconLocation
