import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingCampaignsChartWrapper } from './ReportingCampaignsChartWrapper'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext } from '../../../../models/contexts'
import {
  selectReportingFilters,
  setIsReportingV3,
} from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { ReportingCampaignsTableWrapper } from './ReportingCampaignsTableWrapper'
import {
  EChannelTypeGlobal,
  IDAReportCampaignsTable,
  IDOOHReportCampaignsTable,
  IGenericReportCampaignsTable,
  ISPReportCampaignsTable,
} from '../../../../models/core'
import { convertEParamChannelTypeToEChannelTypeGlobal } from '../../../../utils/helpers'
import { sanitizeDataTestId } from '../../../../utils'
import { getCampaignsEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-campaigns-e-dataset-key-from-channel-type-global'
import { TDatasetWhitelistKey } from '../../models'

export const ReportingCampaignsTemplate = ({
  channelType: propChannelType,
  isHome,
}: {
  channelType?: EChannelTypeGlobal
  isHome?: boolean
}) => {
  let pageTitle = propChannelType
    ? `reporting.header.campaigns.${sanitizeDataTestId(propChannelType)}`
    : 'reporting.header.campaigns'
  pageTitle = isHome ? 'home' : pageTitle

  const intl = useIntl()
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectReportingFilters
  )
  const channelType =
    propChannelType ||
    convertEParamChannelTypeToEChannelTypeGlobal(reportingFilterChannelType)
  dispatch(setIsReportingV3({ IsReportingV3: true }))

  const [tableData, setTableData] = useState<
    | IGenericReportCampaignsTable
    | ISPReportCampaignsTable
    | IDAReportCampaignsTable
    | IDOOHReportCampaignsTable
  >()

  const onTableDataChange = (
    campaignsTableData:
      | IGenericReportCampaignsTable
      | ISPReportCampaignsTable
      | IDAReportCampaignsTable
      | IDOOHReportCampaignsTable
  ) => {
    setTableData(campaignsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getCampaignsEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={
          <ReportingCampaignsChartWrapper channelType={channelType} />
        }
        tableWrapper={
          <ReportingCampaignsTableWrapper
            onTableDataChange={onTableDataChange}
            channelType={channelType}
            isHome={isHome}
          />
        }
        onExportData={onExportData}
      />
    </>
  )
}
