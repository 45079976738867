import { EDAReportAdGroupsColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { doohMetricHeaders } from './dooh-metric-headers'

export const doohAudienceMetricHeaders: Array<TGlobalTables> =
  doohMetricHeaders.filter(
    (header) =>
      !(
        header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
        header === EDAReportAdGroupsColumnHeader.WinRate
      )
  )
