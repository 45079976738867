import {
  EDAReportCampaignsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { spMetricNames } from './sp-metric-names'

export const spProductMetricNames: Array<TGlobalMetrics> = spMetricNames.filter(
  (header) =>
    !(
      header === EDAReportCampaignsColumnHeader.ShareOfVoice ||
      header === EDAReportCampaignsColumnHeader.WinRate ||
      header === ESPReportProductsColumnHeader.AvgRank
    )
)
