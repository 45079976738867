import React, { lazy } from 'react'
import { EUserRole } from '@unfoldrtech/portal-mic'
import { Navigate, Route } from 'react-router-dom'

import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { lazyRetry } from '../../utils/lazyRetry'
import {
  PORTAL_FF_DA,
  PORTAL_FF_DIRECT_DEAL_CREATE,
  PORTAL_FF_DOOH_CREATIVES_REVIEW_VIEW,
  PORTAL_FF_DOOH_INVENTORY_VIEW,
  PORTAL_FF_FORECASTING,
} from '../../utils/constants'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { EAllChannelType } from '../../models'
import RetailerFAQRoutes from './RetailerFAQRoutes'
import RetailerAnalyticsRoutes from './RetailerAnalyticsRoutes'
import RetailerSettingsRoutes from './RetailerSettingsRoutes'

const Retailer = lazy(() => lazyRetry(() => import('../../pages/Retailer')))

const RetailerHome = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Home'))
)

const RetailerCreative = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Creatives/CreativesHome'))
)

const RetailerFallback = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Creatives/Fallbacks/Overview')
  )
)

const RetailerFallbackDisplayAds = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Creatives/Fallbacks/DisplayAds')
  )
)

const RetailerTemplates = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Creatives/Templates/Overview')
  )
)

const RetailerTemplatesDisplayAds = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Creatives/Templates/DisplayAds')
  )
)

const RetailerInventory = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Inventory'))
)
const InventorySponsoredProducts = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Inventory/SponsoredProducts')
  )
)
const InventoryDisplayAds = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Inventory/DisplayAds'))
)

const RetailerAdvertisers = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Advertisers'))
)

const AdvertisersHome = lazy(() =>
  lazyRetry(() =>
    lazyRetry(() => import('../../pages/Retailer/Routes/Advertisers/Overview'))
  )
)

const AdvertisersPermit = lazy(() =>
  lazyRetry(() => import('../../pages/Retailer/Routes/Advertisers/Permit'))
)

const PermitOverview = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Advertisers/Permit/Overview')
  )
)
const PermitChannels = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Advertisers/Permit/Channels')
  )
)
const PermitProducts = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Advertisers/Permit/Products')
  )
)
const PermitUsers = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Advertisers/Permit/Users')
  )
)

const RetailerReviewCreativesOverview = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/ReviewCreatives/Overview')
  )
)

const RetailerReviewCreativesDisplayAds = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/ReviewCreatives/DisplayAds')
  )
)

const RetailerReviewCreativesDigitalOutOfHome = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/ReviewCreatives/DigitalOutOfHome')
  )
)

const RetailerDigitalOutOfHomeInventory = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Inventory/DigitalOutOfHome')
  )
)

const DirectDealsLayout = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/DirectDeals/DirectDealsLayout')
  )
)

const RetailerDirectDeals = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/DirectDeals/RetailerDirectDeals')
  )
)

const ForecastingLayout = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Forecasting/ForecastingLayout')
  )
)

const ForecastingOverview = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Forecasting/ForecastingOverview')
  )
)

const ForecastingDetails = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/Forecasting/ForecastingDetails')
  )
)

const NotFound = lazy(() => lazyRetry(() => import('../../pages/NotFound')))

const RetailerRoutes = () => {
  const featureFlags = useFeatureFlags()

  const isDAFlagEnabled = featureFlags[PORTAL_FF_DA]
  const isReviewDOOHCreativesEnabled =
    featureFlags[PORTAL_FF_DOOH_CREATIVES_REVIEW_VIEW]

  const isDirectDealsEnabled = featureFlags[PORTAL_FF_DIRECT_DEAL_CREATE]
  const isForecastingEnabled = featureFlags[PORTAL_FF_FORECASTING]

  return (
    <>
      <PortalRoutes>
        <Route
          path="retailer/:orgId"
          element={
            <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
              <Retailer />
            </AuthenticatedRoute>
          }
        >
          <Route
            path="home"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <RetailerHome />
              </AuthenticatedRoute>
            }
            /* exact */
          />
          {isDAFlagEnabled && (
            <Route
              path="creatives"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                  <RetailerCreative />
                </AuthenticatedRoute>
              }
              /* exact */
            >
              <Route
                path="fallbacks"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                    <RetailerFallback />
                  </AuthenticatedRoute>
                }
                /* exact */
              >
                <Route
                  path="display-ads"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                      <RetailerFallbackDisplayAds />
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              </Route>
              <Route
                path="templates"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                    <RetailerTemplates />
                  </AuthenticatedRoute>
                }
                /* exact */
              >
                <Route
                  path="display-ads"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                      <RetailerTemplatesDisplayAds />
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              </Route>
            </Route>
          )}

          {(isDAFlagEnabled || isReviewDOOHCreativesEnabled) && (
            <Route
              path="review-creatives"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                  <RetailerReviewCreativesOverview />
                </AuthenticatedRoute>
              }
            >
              {isDAFlagEnabled && (
                <Route
                  path="display-ads"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                      <RetailerReviewCreativesDisplayAds />
                    </AuthenticatedRoute>
                  }
                />
              )}
              {isReviewDOOHCreativesEnabled && (
                <Route
                  path="dooh"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                      <RetailerReviewCreativesDigitalOutOfHome />
                    </AuthenticatedRoute>
                  }
                />
              )}
            </Route>
          )}

          {/* Retailer Inventory Routes */}
          <Route
            path="inventory"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                <RetailerInventory />
              </AuthenticatedRoute>
            }
          >
            <Route
              path="sponsored-products"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                  <InventorySponsoredProducts />
                </AuthenticatedRoute>
              }
              /* exact */
            />
            <Route
              path="display-ads"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                  <InventoryDisplayAds />
                </AuthenticatedRoute>
              }
              /* exact */
            />

            {featureFlags[PORTAL_FF_DOOH_INVENTORY_VIEW] && (
              <Route
                path="dooh"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                    <RetailerDigitalOutOfHomeInventory />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
            )}

            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Retailer - Advertiser Routes */}
          <Route
            path="advertisers"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                <RetailerAdvertisers />
              </AuthenticatedRoute>
            }
          >
            <Route
              path="overview"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                  <AdvertisersHome />
                </AuthenticatedRoute>
              }
              /* exact */
            />

            {/* Retailer Advertiser Permit Routes */}
            {/* TOFIX: This should be :advertiserId */}
            <Route
              path=":advertiserId/permit"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                  <AdvertisersPermit />
                </AuthenticatedRoute>
              }
            >
              <Route
                path="overview"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                    <PermitOverview />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              <Route
                path="channels"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                    <PermitChannels />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              <Route
                path="products"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                    <PermitProducts />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              <Route
                path="users"
                element={
                  <AuthenticatedRoute authRole={EUserRole.RetailerAdmin}>
                    <PermitUsers />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>

          {isDirectDealsEnabled && (
            <Route
              path="direct-deals"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                  <DirectDealsLayout />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<Navigate to="all-channels" />} />

              <Route path="all-channels" element={<RetailerDirectDeals />} />

              <Route
                path="display-ads"
                element={
                  <RetailerDirectDeals channel={EAllChannelType.DisplayAd} />
                }
              />

              <Route
                path="digital-out-of-home"
                element={<RetailerDirectDeals channel={EAllChannelType.Dooh} />}
              />
            </Route>
          )}

          {isForecastingEnabled && (
            <Route
              path="forecasting"
              element={
                <AuthenticatedRoute authRole={EUserRole.RetailerInventory}>
                  <ForecastingLayout />
                </AuthenticatedRoute>
              }
            >
              <Route index element={<ForecastingOverview />} />
              <Route path="details" element={<ForecastingDetails />} />
            </Route>
          )}

          <Route
            path="analytics/*"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <PortalRoutes>
                  <Route index element={<Navigate to="advertisers-all" />} />
                </PortalRoutes>
                <RetailerAnalyticsRoutes />
              </AuthenticatedRoute>
            }
          />

          <Route
            path="faq/*"
            element={
              <AuthenticatedRoute authRole={EUserRole.RetailerInsights}>
                <RetailerFAQRoutes />
              </AuthenticatedRoute>
            }
          />

          <Route
            path="settings/*"
            element={
              <AuthenticatedRoute
                authRole={[
                  EUserRole.AdvertiserInsights,
                  EUserRole.RetailerInsights,
                ]}
              >
                <RetailerSettingsRoutes />
              </AuthenticatedRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Route>
      </PortalRoutes>
    </>
  )
}

export default RetailerRoutes
