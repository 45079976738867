import { EGenericReportCampaignsColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { daMetricHeaders } from './da-metric-headers'

export const daAdgroupMetricHeaders: Array<TGlobalTables> =
  daMetricHeaders.filter(
    (header) =>
      !(
        header === EGenericReportCampaignsColumnHeader.Carts ||
        header === EGenericReportCampaignsColumnHeader.Purchases ||
        header === EGenericReportCampaignsColumnHeader.Revenue ||
        header === EGenericReportCampaignsColumnHeader.Roas
      )
  )
