import React from 'react'
import styled from 'styled-components'
import { Container } from '../Global'

interface PreloadFigureProps {
  width: number
  height: number
}

export const PreloaderBody = styled.div`
  & :last-child {
    border: none;
  }
`

export const PreloadLine = styled.div<PreloadFigureProps>`
  position: relative;
  overflow: hidden;

  &:before {
    @keyframes changeOpacity {
      from {
        left: -100%;
      }

      to {
        left: 100%;
      }
    }
    animation-duration: 1s;
    animation-name: changeOpacity;
    animation-iteration-count: infinite;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(192, 192, 192, 0.4),
      transparent
    );
    transition: all 650ms;
  }

  border-radius: 4px;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-color: #dfdfdf;
`

export interface IPreloaderProps {
  enableHeader?: boolean
  rows: number
  columns: number
}

export const Preloader = ({ rows, columns, enableHeader }: IPreloaderProps) => {
  return (
    <Container
      padding={0}
      marginBottom="var(--gutter-3x)"
      overflow="hidden"
      display="grid"
      border={{
        borderRadius: 'var(--gutter-1x)',
      }}
    >
      {enableHeader ? (
        <Container
          background="var(--background-color-table-header)"
          display="flex"
          alignItems="center"
          padding="var(--gutter-3x)"
          height="100%"
          gap="var(--gutter-5x)"
          maxHeight="72px"
          borderTop={{
            borderRadius: 'var(--gutter-1x)',
          }}
          borderRight={{
            borderRadius: 'var(--gutter-1x)',
          }}
          borderBottom={{
            width: '1px',
            style: 'solid',
            color: 'var(--border-color-default)',
          }}
        >
          {Array(columns)
            .fill(0)
            .map(() => {
              return <PreloadLine width={110} height={20} />
            })}
        </Container>
      ) : null}
      <PreloaderBody>
        {Array(rows)
          .fill(0)
          .map(() => (
            <Container
              display="flex"
              gap="var(--gutter-5x)"
              alignItems="center"
              padding="var(--gutter-3x)"
              borderBottom={{
                width: '1px',
                style: 'solid',
                color: 'var(--border-color-default)',
              }}
              maxHeight="72px"
            >
              {Array(columns)
                .fill(0)
                .map(() => {
                  return <PreloadLine width={110} height={20} />
                })}
            </Container>
          ))}
      </PreloaderBody>
    </Container>
  )
}
