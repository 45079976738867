import {
  TDAAdGroupsPositionsReportTableFilters,
  TDAAdGroupsReportTableFilters,
  TDOOHAdGroupsReportTableFilters,
  TDOOHAdGroupsScreensReportTableFilters,
  TGenericAdGroupsCategoriesReportTableFilters,
  TGenericAdGroupsKeywordsReportTableFilters,
  TGenericAdGroupsReportTableFilters,
  TSPAdGroupsReportTableFilters,
} from '../../../models/types'

type TAdGroupsTableReportingFilters =
  | TGenericAdGroupsReportTableFilters
  | TGenericAdGroupsCategoriesReportTableFilters
  | TGenericAdGroupsKeywordsReportTableFilters
  | TSPAdGroupsReportTableFilters
  | TDAAdGroupsReportTableFilters
  | TDAAdGroupsPositionsReportTableFilters
  | TDOOHAdGroupsReportTableFilters
  | TDOOHAdGroupsScreensReportTableFilters

export const selectAdGroupsTableReportingFilters = ({
  reportingFilters: reportingAdGroupsTableFiltersState,
}: {
  reportingFilters: TAdGroupsTableReportingFilters
}): TAdGroupsTableReportingFilters => reportingAdGroupsTableFiltersState
