import {
  EDAReportAdGroupsColumnHeader,
  EDOOHReportLocationsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  EReportColumnType,
  EReportingMetricNumberType,
  ESPReportProductsColumnHeader,
} from '../../../../models/core'
import { TGlobalMetrics, TGlobalTables } from '../../models'

type TMetricNumberAggregationType = {
  numberType: EReportColumnType
  aggregationType: EReportingMetricNumberType
}
export const metricTypeMap = {} as Record<
  TGlobalMetrics | TGlobalTables,
  TMetricNumberAggregationType
>

metricTypeMap[EGenericReportCampaignsColumnHeader.Impressions] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Clicks] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Ctr] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Costs] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.ShareOfVoice] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.WinRate] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Carts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Purchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Revenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.NewToBrandPurchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.NewToBrandRevenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EGenericReportCampaignsColumnHeader.Roas] = {
  numberType: EReportColumnType.Percentage,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[ESPReportProductsColumnHeader.Cpc] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[ESPReportProductsColumnHeader.AvgRank] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.UniqueReach] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.Frequency] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.Cpm] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Avg,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.PvCarts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.PvPurchases] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EDAReportAdGroupsColumnHeader.PvRevenue] = {
  numberType: EReportColumnType.Currency,
  aggregationType: EReportingMetricNumberType.Sum,
}
metricTypeMap[EDOOHReportLocationsColumnHeader.Playouts] = {
  numberType: EReportColumnType.Number,
  aggregationType: EReportingMetricNumberType.Sum,
}
