import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IAdGroup, IAdGroupUpdate, IError } from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId } from '../utils/helpers'

export default function usePatchAdGroup({
  advertiserId,
  retailerId,
  adGroupId,
}: {
  advertiserId: number
  retailerId: number
  adGroupId: number
}) {
  return useMutation<
    AxiosResponse<IAdGroup>,
    AxiosError<IError>,
    {
      payload: IAdGroupUpdate
    }
  >([`patchAdGroup_${adGroupId}`], (params: { payload: IAdGroupUpdate }) => {
    return coreAPI
      .advertiserCampaigns()
      .patchAdGroup(
        advertiserId,
        retailerId,
        adGroupId,
        generateCorrelationId(),
        params.payload
      )
  })
}
