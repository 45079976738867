import { useMemo } from 'react'
import { XAxisProps, YAxisProps } from 'recharts'
import { useTheme } from 'styled-components'
import { IOrgTheme, TChartMetricData } from '../../../models'
import { ORG_DEFAULT_THEME } from '../../Global'

export const useGetChartXYPropsV3 = (chartData: Array<TChartMetricData>) => {
  const theme: IOrgTheme = useTheme()
  const GUTTER_SPACING =
    theme.spacing?.gutter || ORG_DEFAULT_THEME.spacing?.gutter || 8

  const yAxisProps: Record<string | number, YAxisProps> = useMemo(() => {
    return {}
  }, [chartData])

  const xAxisProps: XAxisProps = useMemo(() => {
    const axisProps: XAxisProps = {
      dataKey: 'date',
    }
    if (chartData.length) {
      const start = chartData[0].date
      const end = chartData.slice(-1)[0].date as string
      axisProps.domain = [start, end]
      axisProps.tickFormatter = (time) => {
        return time
      }
      axisProps.ticks = [start, end]
      axisProps.dy = GUTTER_SPACING * 2
    }

    return axisProps
  }, [chartData])

  return { xAxisProps, yAxisProps }
}
