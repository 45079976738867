import React from 'react'
import { NOT_AVAILABLE } from '../../../../utils/constants'
import {
  EllipsisContainer,
  TooltipWrapper,
  Text,
  PageLinkButton,
} from '../../../Global'

export const TableDataCellAdGroupName = ({
  dataValue = NOT_AVAILABLE,
  navigate,
}: {
  dataValue?: string
  navigate?: () => void
}) => {
  return (
    <TooltipWrapper showTooltip={true} tooltip={dataValue}>
      <EllipsisContainer maxWidth="240px">
        {navigate ? (
          <PageLinkButton onClick={navigate}>
            <Text>{dataValue}</Text>
          </PageLinkButton>
        ) : (
          <Text>{dataValue}</Text>
        )}
      </EllipsisContainer>
    </TooltipWrapper>
  )
}
