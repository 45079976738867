import {
  EDAReportCampaignsColumnHeader,
  ESPReportCampaignsColumnHeader,
} from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { spMetricHeaders } from './sp-metric-headers'

export const spKeywordMetricHeaders: Array<TGlobalTables> =
  spMetricHeaders.filter(
    (header) =>
      !(
        header === ESPReportCampaignsColumnHeader.AvgRank ||
        header === EDAReportCampaignsColumnHeader.ShareOfVoice ||
        header === EDAReportCampaignsColumnHeader.WinRate
      )
  )
