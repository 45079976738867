import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconDooh24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.75 24.75a2 2 0 0 1 2-2h6.5a2 2 0 0 1 2 2V25H6.75v-.25ZM9.75 17.5h4.5v5.25h-4.5z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      width={15}
      height={16.5}
      rx={2}
      transform="matrix(1 0 0 -1 4.5 17.5)"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6.75 15.25h10.5v-12H6.75z"
    />
  </svg>
)
export default SvgIconDooh24
