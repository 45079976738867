import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TReportingFilters, TTranslationKey } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import { selectAdGroupsTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { useGetDefaultSortBy } from '../../hooks/use-get-default-sort-by'
import { AppContext, CampaignContext, EDealType } from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import {
  TDAAdGroupsPositionsTableHeaders,
  TDAAdGroupsTableHeaders,
  TDOOHAdGroupsScreensTableHeaders,
  TDOOHAdGroupsTableHeaders,
  TGenericAdGroupsCategoriesTableHeaders,
  TGenericAdGroupsKeywordsTableHeaders,
  TGenericAdGroupsTableHeaders,
  TSPAdGroupsTableHeaders,
} from '../models'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TDatasetWhitelistKey, TGlobalTables } from '../../models'
import { TableDataCellClicks } from '../../components/table-data-cell/TableDataCellClicks'
import { TableDataCellAvgRank } from '../../components/table-data-cell/TableDataCellAvgRank'
import { TableDataCellCarts } from '../../components/table-data-cell/TableDataCellCarts'
import { TableDataCellPurchases } from '../../components/table-data-cell/TableDataCellPurchases'
import { TableDataCellRevenue } from '../../components/table-data-cell/TableDataCellRevenue'
import { TableDataCellRoas } from '../../components/table-data-cell/TableDataCellRoas'
import {
  EChannelTypeGlobal,
  EDAReportAdGroupsColumnHeader,
  EDAReportAdGroupsPositionsColumnHeader,
  EDAReportCampaignsColumnHeader,
  EDOOHReportAdGroupsColumnHeader,
  EDOOHReportAdGroupsScreensColumnHeader,
  EDOOHReportCampaignsColumnHeader,
  EGenericReportAdGroupsCategoriesColumnHeader,
  EGenericReportAdGroupsColumnHeader,
  EGenericReportAdGroupsKeywordsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  EInventoryTypeGlobal,
  ESPReportAdGroupsColumnHeader,
  ESPReportCampaignsColumnHeader,
  IDAReportAdGroupsPositionsTable,
  IDAReportAdGroupsTable,
  IDAReportCampaignsTable,
  IDOOHReportAdGroupsScreensTable,
  IDOOHReportAdGroupsTable,
  IGenericReportAdGroupsCategoriesTable,
  IGenericReportAdGroupsKeywordsTable,
  IGenericReportAdGroupsTable,
  ISPReportAdGroupsTable,
  ISPReportCampaignsTable,
} from '../../../../models/core'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { getAdGroupsEDatasetKey } from '../helpers/get-ad-groups-e-dataset-key'
import { getTableAndChartAdGroupsFn } from '../helpers/get-table-and-chart-ad-groups-fn'
import { TableDataCellChannelType } from '../../components/table-data-cell/TableDataCellChannelType'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellWinRate } from '../../components/table-data-cell/TableDataCellWinRate'
import { TableDataCellNewToBrandPurchases } from '../../components/table-data-cell/TableDataCellNewToBrandPurchases'
import { TableDataCellNewToBrandRevenue } from '../../components/table-data-cell/TableDataCellNewToBrandRevenue'
import { TableDataCellPlayouts } from '../../components/table-data-cell/TableDataCellPlayouts'
import useShowToast from '../../../../hooks/useShowToast'
import { ErrorToast } from '../../../ToastCard/ErrorToast'
import { TablePreloader } from '../../components/table-preloader'
import { TableDataCellAdGroupName } from '../../components/table-data-cell/TableDataCellAdGroupName'
import { TableDataCellStatusAdGroupWithUpdate } from '../../components/table-data-cell/TableDataCellStatusAdGroupWithUpdate'
import { TableDataCellPlacement } from '../../components/table-data-cell/TableDataCellPlacement'
import { TableDataCellBidPrice } from '../../components/table-data-cell/TableDataCellBidPrice'
import { TableDataCellScreenPositionName } from '../../components/table-data-cell/TableDataCellScreenPositionName'
import { TableDataCellCpc } from '../../components/table-data-cell/TableDataCellCpc'

type TAdGroupsTableHeaders = TGenericAdGroupsTableHeaders &
  TGenericAdGroupsCategoriesTableHeaders &
  TGenericAdGroupsKeywordsTableHeaders &
  TSPAdGroupsTableHeaders &
  TDAAdGroupsTableHeaders &
  TDAAdGroupsPositionsTableHeaders &
  TDOOHAdGroupsTableHeaders &
  TDOOHAdGroupsScreensTableHeaders

type IReportAdGroupsTable =
  | IGenericReportAdGroupsTable
  | IGenericReportAdGroupsCategoriesTable
  | IGenericReportAdGroupsKeywordsTable
  | ISPReportAdGroupsTable
  | IDAReportAdGroupsTable
  | IDAReportAdGroupsPositionsTable
  | IDOOHReportAdGroupsTable
  | IDOOHReportAdGroupsScreensTable

export const ReportingAdGroupsTableWrapper = ({
  onTableDataChange,
  inventoryType,
  channelType,
}: {
  onTableDataChange: (adGroupsTableData: IReportAdGroupsTable) => void
  inventoryType?: EInventoryTypeGlobal
  channelType?: EChannelTypeGlobal
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectAdGroupsTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartAdGroupsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const [sortBy, setSortBy] = useState<TAdGroupsTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()

  const [errorUpdateStatus, setErrorUpdateStatus] = useState<TTranslationKey>()
  const [showUpdateStatusErrorToast, setShowUpdateStatusErrorToast] =
    useShowToast(false)

  useGetDefaultSortBy({
    sortBy,
    sortOrder,
    setSortBy,
    setSortOrder,
    orgType,
    channelType,
  })

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId: campaign?.id,
    inventoryType,
    channelType,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TAdGroupsTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportAdGroupsTable)

  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableDataResponse.datasetKey as TDatasetWhitelistKey) ||
      getAdGroupsEDatasetKey(inventoryType, channelType),
  })

  const processTableDataResponse = (tableDataRes: IReportAdGroupsTable) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === ESPReportCampaignsColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((row) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = row[header as TAdGroupsTableHeaders]
        const navLink = `../adgroups/${row.id}`

        switch (header) {
          case EGenericReportAdGroupsColumnHeader.Name:
          case EGenericReportAdGroupsCategoriesColumnHeader.Name:
          case EGenericReportAdGroupsKeywordsColumnHeader.Name:
          case ESPReportAdGroupsColumnHeader.Name:
          case EDAReportAdGroupsColumnHeader.Name:
          case EDAReportAdGroupsPositionsColumnHeader.Name:
          case EDOOHReportAdGroupsColumnHeader.Name:
          case EDOOHReportAdGroupsScreensColumnHeader.Name:
            dataCell = (
              <TableDataCellAdGroupName
                dataValue={dataValue?.toString()}
                navigate={() => {
                  navigate(navLink, { state: row })
                }}
              />
            )
            break
          case EGenericReportAdGroupsColumnHeader.Status:
          case EGenericReportAdGroupsCategoriesColumnHeader.Status:
          case EGenericReportAdGroupsKeywordsColumnHeader.Status:
          case ESPReportAdGroupsColumnHeader.Status:
          case EDAReportAdGroupsColumnHeader.Status:
          case EDAReportAdGroupsPositionsColumnHeader.Status:
          case EDOOHReportAdGroupsColumnHeader.Status:
          case EDOOHReportAdGroupsScreensColumnHeader.Status:
            dataCell = (
              <TableDataCellStatusAdGroupWithUpdate
                dataValue={dataValue?.toString() as EStatus}
                orgType={orgType}
                id={row.id}
                isDirectDeal={row.campaign_type !== EDealType.Programmatic}
                setErrorUpdateStatus={setErrorUpdateStatus}
                setShowUpdateStatusErrorToast={setShowUpdateStatusErrorToast}
              />
            )
            break
          case EGenericReportAdGroupsColumnHeader.InventoryType:
          case ESPReportAdGroupsColumnHeader.InventoryType:
          case EDAReportAdGroupsColumnHeader.InventoryType:
            dataCell = (
              <TableDataCellPlacement dataValue={dataValue?.toString()} />
            )
            break
          case EDOOHReportAdGroupsColumnHeader.ScreenPositionName:
          case EDOOHReportAdGroupsScreensColumnHeader.ScreenPositionName:
            dataCell = (
              <TableDataCellScreenPositionName
                dataValue={dataValue?.toString()}
              />
            )
            break
          case EGenericReportAdGroupsColumnHeader.ChannelType:
          case EGenericReportAdGroupsCategoriesColumnHeader.ChannelType:
          case EGenericReportAdGroupsKeywordsColumnHeader.ChannelType:
          case ESPReportAdGroupsColumnHeader.ChannelType:
          case EDAReportAdGroupsColumnHeader.ChannelType:
          case EDAReportAdGroupsPositionsColumnHeader.ChannelType:
          case EDOOHReportAdGroupsColumnHeader.ChannelType:
          case EDOOHReportAdGroupsScreensColumnHeader.ChannelType:
            dataCell = (
              <TableDataCellChannelType
                dataValue={dataValue as EChannelTypeGlobal}
              />
            )
            break
          case EDOOHReportAdGroupsColumnHeader.BidPrice:
          case EDOOHReportAdGroupsScreensColumnHeader.BidPrice:
            dataCell = <TableDataCellBidPrice dataValue={Number(dataValue)} />
            break
          case EDOOHReportAdGroupsColumnHeader.Playouts:
          case EDOOHReportAdGroupsScreensColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Impressions:
          case EGenericReportAdGroupsCategoriesColumnHeader.Impressions:
          case EGenericReportAdGroupsKeywordsColumnHeader.Impressions:
          case ESPReportAdGroupsColumnHeader.Impressions:
          case EDAReportAdGroupsColumnHeader.Impressions:
          case EDAReportAdGroupsPositionsColumnHeader.Impressions:
          case EDOOHReportAdGroupsColumnHeader.Impressions:
          case EDOOHReportAdGroupsScreensColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportAdGroupsColumnHeader.Clicks:
          case EGenericReportAdGroupsCategoriesColumnHeader.Clicks:
          case EGenericReportAdGroupsKeywordsColumnHeader.Clicks:
          case ESPReportAdGroupsColumnHeader.Clicks:
          case EDAReportAdGroupsColumnHeader.Clicks:
          case EDAReportAdGroupsPositionsColumnHeader.Clicks:
            dataCell = <TableDataCellClicks dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Ctr:
          case EGenericReportAdGroupsCategoriesColumnHeader.Ctr:
          case EGenericReportAdGroupsKeywordsColumnHeader.Ctr:
          case ESPReportAdGroupsColumnHeader.Ctr:
          case EDAReportAdGroupsColumnHeader.Ctr:
          case EDAReportAdGroupsPositionsColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EDAReportAdGroupsPositionsColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportAdGroupsPositionsColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Costs:
          case EGenericReportAdGroupsCategoriesColumnHeader.Costs:
          case EGenericReportAdGroupsKeywordsColumnHeader.Costs:
          case ESPReportAdGroupsColumnHeader.Costs:
          case EDAReportAdGroupsColumnHeader.Costs:
          case EDAReportAdGroupsPositionsColumnHeader.Costs:
          case EDOOHReportAdGroupsColumnHeader.Costs:
          case EDOOHReportAdGroupsScreensColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.ShareOfVoice:
          case EGenericReportAdGroupsCategoriesColumnHeader.ShareOfVoice:
          case ESPReportAdGroupsColumnHeader.ShareOfVoice:
          case EDAReportAdGroupsColumnHeader.ShareOfVoice:
          case EDOOHReportAdGroupsColumnHeader.ShareOfVoice:
          case EDOOHReportAdGroupsScreensColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportAdGroupsColumnHeader.WinRate:
          case EGenericReportAdGroupsCategoriesColumnHeader.WinRate:
          case ESPReportAdGroupsColumnHeader.WinRate:
          case EDAReportAdGroupsColumnHeader.WinRate:
          case EDOOHReportAdGroupsColumnHeader.WinRate:
          case EDOOHReportAdGroupsScreensColumnHeader.WinRate:
            dataCell = <TableDataCellWinRate dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Carts:
          case EGenericReportAdGroupsCategoriesColumnHeader.Carts:
          case EGenericReportAdGroupsKeywordsColumnHeader.Carts:
          case ESPReportAdGroupsColumnHeader.Carts:
          case EDAReportAdGroupsColumnHeader.Carts:
          case EDAReportAdGroupsPositionsColumnHeader.Carts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EDAReportAdGroupsPositionsColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Purchases:
          case EGenericReportAdGroupsCategoriesColumnHeader.Purchases:
          case EGenericReportAdGroupsKeywordsColumnHeader.Purchases:
          case ESPReportAdGroupsColumnHeader.Purchases:
          case EDAReportAdGroupsColumnHeader.Purchases:
          case EDAReportAdGroupsPositionsColumnHeader.Purchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EDAReportAdGroupsPositionsColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.Revenue:
          case EGenericReportAdGroupsCategoriesColumnHeader.Revenue:
          case EGenericReportAdGroupsKeywordsColumnHeader.Revenue:
          case ESPReportAdGroupsColumnHeader.Revenue:
          case EDAReportAdGroupsColumnHeader.Revenue:
          case EDAReportAdGroupsPositionsColumnHeader.Revenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EDAReportAdGroupsPositionsColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          case EGenericReportAdGroupsColumnHeader.NewToBrandPurchases:
          case EGenericReportAdGroupsCategoriesColumnHeader.NewToBrandPurchases:
          case EGenericReportAdGroupsKeywordsColumnHeader.NewToBrandPurchases:
          case ESPReportAdGroupsColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportAdGroupsColumnHeader.NewToBrandRevenue:
          case EGenericReportAdGroupsCategoriesColumnHeader.NewToBrandRevenue:
          case EGenericReportAdGroupsKeywordsColumnHeader.NewToBrandRevenue:
          case ESPReportAdGroupsColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportAdGroupsColumnHeader.Roas:
          case EGenericReportAdGroupsCategoriesColumnHeader.Roas:
          case EGenericReportAdGroupsKeywordsColumnHeader.Roas:
          case ESPReportAdGroupsColumnHeader.Roas:
          case EDAReportAdGroupsColumnHeader.Roas:
          case EDAReportAdGroupsPositionsColumnHeader.Roas:
            dataCell = <TableDataCellRoas dataValue={Number(dataValue)} />
            break
          case ESPReportCampaignsColumnHeader.Cpc:
            dataCell = <TableDataCellCpc dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.Cpm:
          case EDOOHReportCampaignsColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EGenericReportCampaignsColumnHeader.NewToBrandPurchases:
          case ESPReportCampaignsColumnHeader.NewToBrandPurchases:
            dataCell = (
              <TableDataCellNewToBrandPurchases dataValue={Number(dataValue)} />
            )
            break
          case EGenericReportCampaignsColumnHeader.NewToBrandRevenue:
          case ESPReportCampaignsColumnHeader.NewToBrandRevenue:
            dataCell = (
              <TableDataCellNewToBrandRevenue dataValue={Number(dataValue)} />
            )
            break
          case ESPReportCampaignsColumnHeader.AvgRank:
            dataCell = <TableDataCellAvgRank dataValue={Number(dataValue)} />
            break
          // DA Exclusive
          case EDAReportCampaignsColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportCampaignsColumnHeader.PvCarts:
            dataCell = <TableDataCellCarts dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.PvPurchases:
            dataCell = <TableDataCellPurchases dataValue={Number(dataValue)} />
            break
          case EDAReportCampaignsColumnHeader.PvRevenue:
            dataCell = <TableDataCellRevenue dataValue={Number(dataValue)} />
            break
          // DOOH Exclusive
          case EDOOHReportCampaignsColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (sortBy && sortOrder && startDate && endDate) {
      refetch()
    }
  }, [sortBy, sortOrder, startDate, endDate, inventoryType])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(
        tableDataResponse as ISPReportCampaignsTable & IDAReportCampaignsTable
      )
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length &&
        tableData?.rowList?.length > 0 &&
        !isFetching && (
          <>
            {tableData?.rowList && (
              <ReportTableV3
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
          </>
        )}
      {tableData?.rowList?.length === 0 && (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.Campaign, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}

      <ErrorToast
        show={showUpdateStatusErrorToast}
        onClose={() => {
          setShowUpdateStatusErrorToast(false)
          setErrorUpdateStatus(undefined)
        }}
        translationKey={errorUpdateStatus}
      />

      {isFetching && <TablePreloader />}
    </>
  )
}
