import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.333 13.333h1.334A1.333 1.333 0 0 1 28 14.666V28a1.333 1.333 0 0 1-1.333 1.333H5.333A1.333 1.333 0 0 1 4 28V14.666a1.333 1.333 0 0 1 1.333-1.333h1.334V12a9.333 9.333 0 0 1 18.666 0v1.333ZM6.667 16v10.666h18.666V16H6.667Zm8 2.666h2.666V24h-2.666v-5.334Zm8-5.333V12a6.666 6.666 0 0 0-13.334 0v1.333h13.334Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIconLocked
