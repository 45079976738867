import {
  EDAReportCampaignsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportProductsColumnHeader,
} from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { TGlobalMetrics } from '../../../models'

export const daMetricNames: Array<TGlobalMetrics> = [
  EDAReportCampaignsColumnHeader.Impressions,
  EDAReportCampaignsColumnHeader.UniqueReach,
  EDAReportCampaignsColumnHeader.Frequency,
  EDAReportCampaignsColumnHeader.Clicks,
  EDAReportCampaignsColumnHeader.Ctr,
  EDAReportCampaignsColumnHeader.Costs,
  EDAReportCampaignsColumnHeader.Cpm,
  EDAReportCampaignsColumnHeader.ShareOfVoice,
  EDAReportCampaignsColumnHeader.WinRate,
  // ESPReportProductsColumnHeader.AvgRank,
  ESPReportProductsColumnHeader.Carts,
  EGenericReportCampaignsColumnHeader.Purchases,
  EGenericReportCampaignsColumnHeader.Revenue,
  EGenericReportCampaignsColumnHeader.Roas,
  EDAReportCampaignsColumnHeader.PvCarts,
  EDAReportCampaignsColumnHeader.PvPurchases,
  EDAReportCampaignsColumnHeader.PvRevenue,
]
