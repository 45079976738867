import React from 'react'
import { NOT_AVAILABLE } from '../../../../utils/constants'
import {
  EllipsisContainer,
  TooltipWrapper,
  CurrentInventoryPageText,
} from '../../../Global'

export const TableDataCellPlacement = ({
  dataValue = NOT_AVAILABLE,
}: {
  dataValue?: string
}) => {
  return (
    <TooltipWrapper showTooltip={true} tooltip={dataValue}>
      <EllipsisContainer maxWidth="240px">
        <CurrentInventoryPageText inventoryType={dataValue} />
      </EllipsisContainer>
    </TooltipWrapper>
  )
}
