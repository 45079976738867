import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDACategoriesReportChartFilters,
  TPPAdvertiserId,
  TSPCategoriesReportChartFilters,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamChannelTypeGlobal,
  ETimeBucketType,
  IReportingCategoriesChartResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetAdvertiserReportingCategoriesChartV3({
  advertiserId,
  retailerId,
  campaignId,
  adGroupId,
  channelType,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & (TDACategoriesReportChartFilters | TSPCategoriesReportChartFilters) &
  TPPAdvertiserId) {
  const queryKeyName = `getAdvertiserReportingCategoriesChart_${channelType}_${advertiserId}_${retailerId}`

  return useQuery<
    AxiosResponse<IReportingCategoriesChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate, channelType }],
    () => {
      return coreAPIV3
        .advertiserReporting()
        .getAdvertiserCategoriesReportChart(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          campaignId,
          adGroupId,
          channelType as EParamChannelTypeGlobal,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType
        )
    },
    {
      suspense,
      enabled,
    }
  )
}
