import {
  EChannelTypeGlobal,
  EDatasetKey,
  EInventoryTypeGlobal,
} from '../../../../models/core'

export function getAdGroupsEDatasetKey(
  inventoryType?: EInventoryTypeGlobal,
  channelType?: EChannelTypeGlobal
) {
  if (!inventoryType && !channelType) {
    return EDatasetKey.AdgroupGeneric
  }

  if (inventoryType) {
    switch (inventoryType) {
      case EInventoryTypeGlobal.Category:
        return EDatasetKey.AdgroupGenericCategory
      case EInventoryTypeGlobal.Keyword:
        return EDatasetKey.AdgroupGenericKeyword
      case EInventoryTypeGlobal.PagePosition:
        return EDatasetKey.AdgroupDaPagePosition
      case EInventoryTypeGlobal.Screen:
        return EDatasetKey.AdgroupDoohScreen
      default:
        return EDatasetKey.AdgroupGeneric
    }
  }

  if (channelType) {
    switch (channelType) {
      case EChannelTypeGlobal.SponsoredProduct:
        return EDatasetKey.AdgroupSp
      case EChannelTypeGlobal.DisplayAd:
        return EDatasetKey.AdgroupDa
      case EChannelTypeGlobal.DigitalOutOfHome:
        return EDatasetKey.AdgroupDooh
      default:
        return EDatasetKey.AdgroupGeneric
    }
  }
}
