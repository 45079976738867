import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.75 6.6c0-1.954-3.303-3.3-7.313-3.3-4.009 0-7.312 1.346-7.312 3.3 0 1.954 3.303 3.3 7.313 3.3 4.009 0 7.312-1.346 7.312-3.3Zm-13.5 0c0-1.083 2.74-2.2 6.188-2.2 3.446 0 6.187 1.117 6.187 2.2 0 1.083-2.74 2.2-6.188 2.2-3.446 0-6.187-1.117-6.187-2.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 9.91V6.6h1.125v3.3c0 .03.002.061.007.092-.365-.04-.743-.068-1.132-.082Zm5.647 3.21c.536.053 1.093.08 1.665.08 4.01 0 7.313-1.346 7.313-3.3V6.6h-1.125v3.3c0 1.083-2.74 2.2-6.188 2.2-.588 0-1.156-.032-1.694-.092a.66.66 0 0 1 .007.092c0 .091.008.464.022 1.02Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.858 16.428c.51.047 1.038.072 1.58.072 4.009 0 7.312-1.346 7.312-3.3V9.9h-1.125v3.3c0 1.083-2.74 2.2-6.188 2.2-.557 0-1.096-.03-1.608-.083l.03 1.111ZM11.25 9.991V9.9h-1.125v.01c.387.014.763.041 1.125.081Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.875 13.2c0-1.954-3.303-3.3-7.313-3.3-4.009 0-7.312 1.346-7.312 3.3 0 1.954 3.303 3.3 7.313 3.3 4.009 0 7.312-1.346 7.312-3.3Zm-13.5 0c0-1.083 2.74-2.2 6.188-2.2 3.446 0 6.187 1.117 6.187 2.2 0 1.083-2.74 2.2-6.188 2.2-3.446 0-6.187-1.117-6.187-2.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 16.5c0 1.954 3.303 3.3 7.313 3.3 4.009 0 7.312-1.346 7.312-3.3v-3.3H15.75v3.3c0 1.083-2.74 2.2-6.188 2.2-3.446 0-6.187-1.117-6.187-2.2v-3.3H2.25v3.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 19.8c0 1.954 3.303 3.3 7.313 3.3 4.009 0 7.312-1.346 7.312-3.3v-3.3H15.75v3.3c0 1.083-2.74 2.2-6.188 2.2-3.446 0-6.187-1.117-6.187-2.2v-3.3H2.25v3.3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 23.1c0 1.954 3.303 3.3 7.313 3.3 4.009 0 7.312-1.346 7.312-3.3v-3.3H15.75v3.3c0 1.083-2.74 2.2-6.188 2.2-3.446 0-6.187-1.117-6.187-2.2v-3.3H2.25v3.3ZM15.948 19.736c.482.042.98.064 1.49.064 4.009 0 7.312-1.346 7.312-3.3v-3.3h-1.125v3.3c0 1.083-2.74 2.2-6.188 2.2-.525 0-1.034-.026-1.519-.074l.03 1.11Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.04 23.044c.453.037.92.056 1.398.056 4.009 0 7.312-1.346 7.312-3.3v-3.3h-1.125v3.3c0 1.083-2.74 2.2-6.188 2.2-.492 0-.97-.023-1.428-.065l.03 1.109Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgIconMoney
