import { EDealType } from '@unfoldrtech/portal-mic'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { selectReportingFilters } from '../../../store/reportingFilters'
import { CampaignContext } from '../../../models'

export const useGetIsCurrentCampaignDirectDeal = () => {
  const location = useLocation()
  const campaignFilters = useSelector(selectReportingFilters)
  const campaign = useContext(CampaignContext)
  const ddDealTypes = [EDealType.ExclusiveDeal, EDealType.StandardDeal]

  return (
    (ddDealTypes.includes(campaignFilters.dealType) &&
      location.pathname.includes('analytics')) ||
    ddDealTypes.includes(campaign.dealType)
  )
}
