import { css, CSSProperties } from 'styled-components'

export type TAlignmentMixin = Pick<
  CSSProperties,
  | 'textAlign'
  | 'alignSelf'
  | 'alignItems'
  | 'alignContent'
  | 'justifyContent'
  | 'justifyItems'
  | 'verticalAlign'
  | 'placeSelf'
  | 'justifySelf'
  | 'float'
>

export const Alignment = ({
  textAlign,
  alignSelf,
  alignItems,
  alignContent,
  justifyContent,
  justifyItems,
  verticalAlign,
  placeSelf,
  justifySelf,
  float,
}: TAlignmentMixin) => css`
  text-align: ${textAlign};
  align-self: ${alignSelf};
  align-items: ${alignItems};
  align-content: ${alignContent};
  justify-content: ${justifyContent};
  justify-items: ${justifyItems};
  vertical-align: ${verticalAlign};
  place-self: ${placeSelf};
  justify-self: ${justifySelf};
  float: ${float};
`

export const CenterAlignPreset = css`
  ${Alignment({
    textAlign: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  })}
`
