import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId, getTimezone } from '../utils/helpers'
import {
  TDAAdGroupsPositionsReportChartFilters,
  TDAAdGroupsReportChartFilters,
  TDOOHAdGroupsReportChartFilters,
  TDOOHAdGroupsScreensReportChartFilters,
  TGenericAdGroupsCategoriesReportChartFilters,
  TGenericAdGroupsKeywordsReportChartFilters,
  TGenericAdGroupsReportChartFilters,
  TPPAdvertiserId,
  TSPAdGroupsReportChartFilters,
} from '../models/types'
import { EReportingTimeWindow } from '../models'
import {
  EParamInventoryTypeGlobal,
  ETimeBucketType,
  IReportingAdGroupsChartResponse,
} from '../models/core'
import { coreAPIV3 } from '../services/coreAPIV3'

export default function useGetAdvertiserReportingAdGroupsChartV3({
  advertiserId,
  retailerId,
  inventoryType,
  campaignId,
  timeWindow,
  startDate,
  endDate,
  tzZone,
  aggregationType,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & (
  | TGenericAdGroupsReportChartFilters
  | TGenericAdGroupsCategoriesReportChartFilters
  | TGenericAdGroupsKeywordsReportChartFilters
  | TSPAdGroupsReportChartFilters
  | TDAAdGroupsReportChartFilters
  | TDAAdGroupsPositionsReportChartFilters
  | TDOOHAdGroupsReportChartFilters
  | TDOOHAdGroupsScreensReportChartFilters
) &
  TPPAdvertiserId) {
  const queryKeyName = `getAdvertiserReportingAdGroupsChart_${inventoryType}_${advertiserId}_${retailerId}`

  return useQuery<
    AxiosResponse<IReportingAdGroupsChartResponse>,
    AxiosError<IError>
  >(
    [
      queryKeyName,
      { start: startDate, end: endDate, inventoryType, campaignId },
    ],
    () => {
      return coreAPIV3
        .advertiserReporting()
        .getAdvertiserAdGroupsReportChartV3(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          campaignId,
          inventoryType as EParamInventoryTypeGlobal,
          timeWindow as unknown as EReportingTimeWindow,
          startDate,
          endDate,
          tzZone || getTimezone(),
          aggregationType as ETimeBucketType
        )
    },
    { suspense, enabled }
  )
}
