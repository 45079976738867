import React from 'react'
import styled, { useTheme } from 'styled-components'
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  XAxis,
  YAxis,
  Tooltip,
  YAxisProps,
  XAxisProps,
} from 'recharts'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { IOrgTheme } from '@unfoldrtech/portal-mic'
import { useSelector } from 'react-redux'
import { Container, ORG_DEFAULT_THEME } from '../Global'
import I18NText from '../I18NText'
import { TTranslationKey, TChartMetricData } from '../../models/types'
import { DEFAULT_AXIS_PROPS } from './LineChart'
import { StyledTooltipV3 } from './StyledTooltipV3'
import { CustomizedTick } from './CustomizedTick'
import { selectCampaignsChartReportingFilters } from '../../store/reportingFilters'
import { ETimeBucketType } from '../../models/core'

const LineChartContainer = styled(Container)<{ tickSpace?: boolean }>`
  width: 100%;
  height: 300px;
  ${(props) =>
    props.tickSpace &&
    `.xAxis .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick:last-child text {
    transform: translate(-18px, 0);
  }}}`}
`

function getAxisDef(
  metricColors: string[],
  index: number,
  metricName: string
): { axisId: string; strokeColor: string; axisOrientation: 'left' | 'right' } {
  const strokeColor = metricColors[index % metricColors.length]
  const axisOrientation = index % 2 === 0 ? 'left' : 'right'
  const key = `${metricName}-${index}`
  const axisId = `${axisOrientation}-${key}`
  return { axisId, strokeColor, axisOrientation }
}

export function LineChartV3({
  chartData,
  metrics,
  metricUnits = [],
  metricColors = JSON.parse(JSON.stringify(schemeTableau10)) as Array<string>,
  xAxisProps = { dataKey: 'date' },
  yAxiiProps = {},
  noDataTranslationKey = 'no.data',
}: {
  chartData: Array<TChartMetricData>
  metrics: Array<string>
  metricColors?: Array<string>
  metricUnits?: Array<string>
  xAxisProps?: XAxisProps
  yAxiiProps?: Record<string | number, YAxisProps>
  noDataTranslationKey?: TTranslationKey
}) {
  const theme: IOrgTheme = useTheme()
  const { aggregationType } = useSelector(selectCampaignsChartReportingFilters)
  const GUTTER_SPACING =
    theme.spacing?.gutter || ORG_DEFAULT_THEME.spacing?.gutter || 8

  if (metrics?.length <= 0) {
    return (
      <>
        <Container
          textAlign="center"
          preset="h3"
          width="100%"
          padding="var(--padding-default)"
        >
          <I18NText id={noDataTranslationKey} />
        </Container>
      </>
    )
  }

  return (
    <LineChartContainer
      tickSpace={
        chartData.length > 1 &&
        (aggregationType === ETimeBucketType.Monthly ||
          aggregationType === ETimeBucketType.Weekly)
      }
    >
      <ResponsiveContainer>
        <RechartsLineChart
          data={chartData}
          margin={{
            top: GUTTER_SPACING,
            right: GUTTER_SPACING,
            left: 0,
            bottom: GUTTER_SPACING * 3,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            stroke="var(--border-color-filter-box-shadow)"
          />
          <Tooltip content={<StyledTooltipV3 />} />

          <XAxis
            {...DEFAULT_AXIS_PROPS}
            fontFamily="OpenSans"
            stroke="var(--font-color-default)"
            opacity="0.5"
            tickLine={true}
            tick={(props) => <CustomizedTick {...props} />}
            {...xAxisProps}
          />

          {metrics.map((metricName, index) => {
            const { axisId, strokeColor, axisOrientation } = getAxisDef(
              metricColors,
              index,
              metricName
            )

            return (
              <YAxis
                {...DEFAULT_AXIS_PROPS}
                key={`axis-${axisId}`}
                yAxisId={axisId}
                stroke={strokeColor}
                color={strokeColor}
                orientation={axisOrientation}
                dx={index % 2 === 0 ? -GUTTER_SPACING : GUTTER_SPACING}
                {...yAxiiProps[index]}
              />
            )
          })}

          {metrics.map((metricName, index) => {
            const { axisId, strokeColor } = getAxisDef(
              metricColors,
              index,
              metricName
            )

            return (
              <Line
                key={`axis-${axisId}`}
                type="linear"
                yAxisId={axisId}
                dataKey={metricName}
                stroke={strokeColor}
                unit={metricUnits[index] || ''}
                dot={false}
              />
            )
          })}
        </RechartsLineChart>
      </ResponsiveContainer>
    </LineChartContainer>
  )
}
