import { EGenericReportCampaignsColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { daMetricNames } from './da-metric-names'

export const daAdgroupMetricNames: Array<TGlobalMetrics> = daMetricNames.filter(
  (header) =>
    !(
      header === EGenericReportCampaignsColumnHeader.Carts ||
      header === EGenericReportCampaignsColumnHeader.Purchases ||
      header === EGenericReportCampaignsColumnHeader.Revenue ||
      header === EGenericReportCampaignsColumnHeader.Roas
    )
)
