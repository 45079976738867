import {
  EDAReportAdGroupsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { daMetricNames } from './da-metric-names'

export const daProductMetricNames: Array<TGlobalMetrics> = daMetricNames.filter(
  (header) =>
    !(
      header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
      header === EDAReportAdGroupsColumnHeader.WinRate ||
      header === ESPReportProductsColumnHeader.AvgRank ||
      header === EDAReportAdGroupsColumnHeader.Frequency
    )
)
