import { EGenericReportCampaignsColumnHeader } from '../../../../../models/core'
import { TGlobalTables } from '../../../models'

export const genericMetricHeaders: Array<TGlobalTables> = [
  EGenericReportCampaignsColumnHeader.Impressions,
  EGenericReportCampaignsColumnHeader.Clicks,
  EGenericReportCampaignsColumnHeader.Ctr,
  EGenericReportCampaignsColumnHeader.Costs,
  EGenericReportCampaignsColumnHeader.ShareOfVoice,
  EGenericReportCampaignsColumnHeader.WinRate,
  // ESPReportProductsColumnHeader.AvgRank,
  EGenericReportCampaignsColumnHeader.Carts,
  EGenericReportCampaignsColumnHeader.Purchases,
  EGenericReportCampaignsColumnHeader.Revenue,
  EGenericReportCampaignsColumnHeader.Roas,
  EGenericReportCampaignsColumnHeader.NewToBrandPurchases,
  EGenericReportCampaignsColumnHeader.NewToBrandRevenue,
]
