import { EParamInventoryType } from '@unfoldrtech/portal-mic'
import { EInventoryTypeGlobal } from '../../models/core'

export function convertEParamInventoryTypeToEInventoryTypeGlobal(
  inventoryType?: EParamInventoryType
) {
  switch (inventoryType) {
    case EParamInventoryType.Category:
      return EInventoryTypeGlobal.Category
    case EParamInventoryType.Keyword:
      return EInventoryTypeGlobal.Keyword
    case EParamInventoryType.PagePosition:
      return EInventoryTypeGlobal.PagePosition
    case EParamInventoryType.Screen:
      return EInventoryTypeGlobal.Screen
    default:
      break
  }
}
