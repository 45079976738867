import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { EPricingStrategy } from '@unfoldrtech/portal-mic'

import {
  Container,
  StyledButton,
  StyledInput2,
  StyledPrefix,
} from '../../../Global'
import I18NText from '../../../I18NText'
import useGetCurrentCurrency from '../../../../hooks/useGetCurrentCurrency'

export const TableDataCellBidPricePopoverContent = ({
  bid,
  floorPrice,
  pricingStrategy,
  handleBidPriceSubmit,
  isUpdating,
}: {
  bid: number
  floorPrice: number
  pricingStrategy: EPricingStrategy
  handleBidPriceSubmit: (bid: number) => void
  isUpdating: boolean
}) => {
  const intl = useIntl()
  const { symbol: currencySymbol } = useGetCurrentCurrency()

  const defaultValues = {
    bidPrice: bid || 0,
  }

  const onSubmit = (data: { bidPrice: number }) => {
    handleBidPriceSubmit(data.bidPrice)
  }

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  return (
    <Container width="250px">
      <I18NText
        id="advertiser.analytics.change.bid.price"
        preset="h6"
        fontWeight={500}
      />

      <Container
        width="100%"
        paddingTop="var(--gutter-2x)"
        marginBottom="var(--gutter-2x)"
      >
        <Controller
          name="bidPrice"
          control={control}
          render={({ field }) => (
            <StyledInput2
              id="bidPrice"
              field={field}
              error={errors.bidPrice}
              type="number"
              inputPadding={{
                paddingLeft: 'var(--gutter-1x)',
              }}
              containerSize={{
                height: '40px',
                width: '100%',
              }}
              prefix={
                <StyledPrefix lineHeight={1}>{currencySymbol}</StyledPrefix>
              }
            />
          )}
          rules={{
            validate: (value) => {
              let errMsgId = ''

              if (!value && value !== 0) {
                errMsgId = 'error.required'
              } else if (value < 0.000000001) {
                errMsgId = 'retailer.errors.min.zero.gt'
              } else if (value < floorPrice) {
                errMsgId =
                  pricingStrategy === EPricingStrategy.Cpc
                    ? 'advertiser.campaigns.ad.group.targeting.form.cpc.bid.lesser.than.floor.price'
                    : 'advertiser.campaigns.ad.group.targeting.form.cpm.bid.lesser.than.floor.price'
              }
              return errMsgId.length
                ? intl.formatMessage({
                    id: errMsgId,
                  })
                : true
            },
          }}
        />
      </Container>

      <StyledButton
        width="100%"
        disabled={!isValid || isUpdating}
        onClick={handleSubmit(onSubmit)}
      >
        <I18NText id="apply" />
      </StyledButton>
    </Container>
  )
}
