import { EDAReportAdGroupsColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'
import { doohMetricNames } from './dooh-metric-names'

export const doohAudienceMetricNames: Array<TGlobalMetrics> =
  doohMetricNames.filter(
    (header) =>
      !(
        header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
        header === EDAReportAdGroupsColumnHeader.WinRate
      )
  )
