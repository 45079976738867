import {
  EDAReportAdGroupsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../../../models/core'
import { TGlobalTables } from '../../../models'
import { spMetricHeaders } from './sp-metric-headers'

export const spProductMetricHeaders: Array<TGlobalTables> =
  spMetricHeaders.filter(
    (header) =>
      !(
        header === EDAReportAdGroupsColumnHeader.ShareOfVoice ||
        header === EDAReportAdGroupsColumnHeader.WinRate ||
        header === ESPReportProductsColumnHeader.AvgRank
      )
  )
