import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { EOrgType } from '@unfoldrtech/portal-mic'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDAReportAudiencesColumnHeader,
  EDatasetKey,
  EDOOHReportLocationsColumnHeader,
  ESPReportProductsColumnHeader,
} from '../../../models'
import { TDatasetWhitelistKey, TGlobalTables } from '../models'
import { TGlobalTableDataType } from '../models/types/global-table-data.type'
import {
  EDAReportCampaignsColumnHeader,
  EDAReportKeywordsColumnHeader,
  EDAReportPositionsColumnHeader,
  EDAReportProductsColumnHeader,
  EDAReportCategoriesColumnHeader,
  EDOOHReportCampaignsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportCampaignsColumnHeader,
  ESPReportKeywordsColumnHeader,
  ESPReportCategoriesColumnHeader,
  EDAReportAdGroupsColumnHeader,
  EGenericReportAdGroupsColumnHeader,
  EGenericReportAdGroupsKeywordsColumnHeader,
  EGenericReportAdGroupsCategoriesColumnHeader,
  ESPReportAdGroupsColumnHeader,
  EDAReportAdGroupsPositionsColumnHeader,
  EDOOHReportAdGroupsColumnHeader,
  EDOOHReportAdGroupsScreensColumnHeader,
  EReportAdvertiserColumnHeader,
  EReportColumnHeader,
} from '../../../models/core'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import {
  genericMetricHeaders,
  spMetricHeaders,
  daMetricHeaders,
  doohMetricHeaders,
  daKeywordMetricHeaders,
  daProductMetricHeaders,
  spProductMetricHeaders,
  genericAdgroupSearchMetricHeaders,
  spKeywordMetricHeaders,
  daAudienceMetricHeaders,
  doohAudienceMetricHeaders,
  daAdgroupMetricHeaders,
} from './constants'
import { isCurrency } from '../helpers'

export type TReportTableEntity =
  | 'ad.groups'
  | 'advertiser'
  | 'audiences'
  | 'campaigns'
  | 'categories'
  | 'positions'
  | 'locations'
  | 'keywords'
  | 'products'

export const useGetDatasetKeyTableWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()
  const featureFlags = useFeatureFlags()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const orgType = appContext.userOrg!.type

  const whitelist = {} as Record<
    TDatasetWhitelistKey,
    Array<TGlobalTableDataType>
  >

  const getGlobalTableDataType = ({
    header,
    entity,
  }: {
    header: TGlobalTables
    entity?: TReportTableEntity
  }): TGlobalTableDataType => {
    return {
      header,
      translatedMetricName: intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${entity ?? header}`,
      }),
      translatedTooltipMetricName: intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${
          entity ?? header
        }.tooltip`,
      }),
      isCurrency: isCurrency(header),
    }
  }

  const getTableWhitelist = (
    headers: Array<TGlobalTables>,
    entity: TReportTableEntity
  ) => {
    return headers.map((header) =>
      getGlobalTableDataType({
        header,
        entity: header === EReportColumnHeader.Name ? entity : undefined,
      })
    )
  }

  whitelist[EDatasetKey.Advertiser] = getTableWhitelist(
    [
      EReportAdvertiserColumnHeader.Name,
      EReportAdvertiserColumnHeader.Status,
      EReportAdvertiserColumnHeader.PermitStatus,
      ...genericMetricHeaders,
    ],
    'advertiser'
  )

  whitelist[EDatasetKey.CampaignGeneric] = getTableWhitelist(
    [
      EGenericReportCampaignsColumnHeader.Name,
      EGenericReportCampaignsColumnHeader.CampaignType,
      EGenericReportCampaignsColumnHeader.AdvertiserName,
      EGenericReportCampaignsColumnHeader.Status,
      EGenericReportCampaignsColumnHeader.ChannelType,
      EGenericReportCampaignsColumnHeader.Budget,
      EGenericReportCampaignsColumnHeader.StartDate,
      EGenericReportCampaignsColumnHeader.EndDate,
      ...genericMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignSp] = getTableWhitelist(
    [
      ESPReportCampaignsColumnHeader.Name,
      ESPReportCampaignsColumnHeader.CampaignType,
      ESPReportCampaignsColumnHeader.AdvertiserName,
      ESPReportCampaignsColumnHeader.Status,
      ESPReportCampaignsColumnHeader.ChannelType,
      ESPReportCampaignsColumnHeader.Budget,
      ESPReportCampaignsColumnHeader.StartDate,
      ESPReportCampaignsColumnHeader.EndDate,
      ...spMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignDa] = getTableWhitelist(
    [
      EDAReportCampaignsColumnHeader.Name,
      EDAReportCampaignsColumnHeader.CampaignType,
      EDAReportCampaignsColumnHeader.AdvertiserName,
      EDAReportCampaignsColumnHeader.Status,
      EDAReportCampaignsColumnHeader.ChannelType,
      EDAReportCampaignsColumnHeader.Budget,
      EDAReportCampaignsColumnHeader.StartDate,
      EDAReportCampaignsColumnHeader.EndDate,
      ...daMetricHeaders,
    ],
    'campaigns'
  )
  whitelist[EDatasetKey.CampaignDooh] = getTableWhitelist(
    [
      EDOOHReportCampaignsColumnHeader.Name,
      EDOOHReportCampaignsColumnHeader.CampaignType,
      EDOOHReportCampaignsColumnHeader.AdvertiserName,
      EDOOHReportCampaignsColumnHeader.Status,
      EDOOHReportCampaignsColumnHeader.ChannelType,
      EDOOHReportCampaignsColumnHeader.Budget,
      EDOOHReportCampaignsColumnHeader.StartDate,
      EDOOHReportCampaignsColumnHeader.EndDate,
      ...doohMetricHeaders,
    ],
    'campaigns'
  )

  whitelist[EDatasetKey.AdgroupGeneric] = getTableWhitelist(
    [
      EGenericReportAdGroupsColumnHeader.Name,
      EGenericReportAdGroupsColumnHeader.Status,
      EGenericReportAdGroupsColumnHeader.InventoryType,
      EGenericReportAdGroupsColumnHeader.ChannelType,
      ...genericMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupGenericCategory] = getTableWhitelist(
    [
      EGenericReportAdGroupsCategoriesColumnHeader.Name,
      EGenericReportAdGroupsCategoriesColumnHeader.Status,
      EGenericReportAdGroupsCategoriesColumnHeader.ChannelType,
      ...genericMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupGenericKeyword] = getTableWhitelist(
    [
      EGenericReportAdGroupsKeywordsColumnHeader.Name,
      EGenericReportAdGroupsKeywordsColumnHeader.Status,
      EGenericReportAdGroupsKeywordsColumnHeader.ChannelType,
      ...genericAdgroupSearchMetricHeaders, // exception
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDaPagePosition] = getTableWhitelist(
    [
      EDAReportAdGroupsPositionsColumnHeader.Name,
      EDAReportAdGroupsPositionsColumnHeader.Status,
      EDAReportAdGroupsPositionsColumnHeader.ChannelType,
      ...daAdgroupMetricHeaders, // exception
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDoohScreen] = getTableWhitelist(
    [
      EDOOHReportAdGroupsScreensColumnHeader.Name,
      EDOOHReportAdGroupsScreensColumnHeader.Status,
      EDOOHReportAdGroupsScreensColumnHeader.ChannelType,
      EDOOHReportAdGroupsScreensColumnHeader.ScreenPositionName,
      EDOOHReportAdGroupsScreensColumnHeader.BidPrice,
      ...doohMetricHeaders,
    ],
    'ad.groups'
  )

  whitelist[EDatasetKey.ProductSp] = getTableWhitelist(
    [
      ESPReportProductsColumnHeader.Image,
      ESPReportProductsColumnHeader.Name,
      ESPReportProductsColumnHeader.Campaigns,
      ESPReportProductsColumnHeader.Adgroups,
      ESPReportProductsColumnHeader.InStock,
      ...spProductMetricHeaders, // exception
    ],
    'products'
  )
  whitelist[EDatasetKey.ProductDa] = getTableWhitelist(
    [
      EDAReportProductsColumnHeader.Image,
      EDAReportProductsColumnHeader.Name,
      EDAReportProductsColumnHeader.Campaigns,
      EDAReportProductsColumnHeader.Adgroups,
      EDAReportProductsColumnHeader.InStock,
      ...daProductMetricHeaders, // exception
    ],
    'products'
  )

  whitelist[EDatasetKey.AdgroupSp] = getTableWhitelist(
    [
      ESPReportAdGroupsColumnHeader.Name,
      ESPReportAdGroupsColumnHeader.Status,
      ESPReportAdGroupsColumnHeader.InventoryType,
      ...spMetricHeaders,
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDa] = getTableWhitelist(
    [
      EDAReportAdGroupsColumnHeader.Name,
      EDAReportAdGroupsColumnHeader.Status,
      EDAReportAdGroupsColumnHeader.InventoryType,
      ...daAdgroupMetricHeaders, // exception
    ],
    'ad.groups'
  )
  whitelist[EDatasetKey.AdgroupDooh] = getTableWhitelist(
    [
      EDOOHReportAdGroupsColumnHeader.Name,
      EDOOHReportAdGroupsColumnHeader.Status,
      EDOOHReportAdGroupsColumnHeader.ScreenPositionName,
      EDOOHReportAdGroupsColumnHeader.BidPrice,
      ...doohMetricHeaders,
    ],
    'ad.groups'
  )

  whitelist[EDatasetKey.PositionDa] = getTableWhitelist(
    [
      EDAReportPositionsColumnHeader.Name,
      EDAReportPositionsColumnHeader.Adgroups,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.AudienceDa] = getTableWhitelist(
    [
      EDAReportAudiencesColumnHeader.Name,
      EDAReportAudiencesColumnHeader.Adgroups,
      EDAReportAudiencesColumnHeader.Campaigns,
      EDAReportAudiencesColumnHeader.Positions,
      ...daAudienceMetricHeaders, // exception
    ],
    'audiences'
  )
  whitelist[EDatasetKey.AudienceDooh] = getTableWhitelist(
    [
      EDAReportAudiencesColumnHeader.Name,
      EDAReportAudiencesColumnHeader.Adgroups,
      EDAReportAudiencesColumnHeader.Campaigns,
      EDAReportAudiencesColumnHeader.Positions,
      ...doohAudienceMetricHeaders, // exception
    ],
    'audiences'
  )

  whitelist[EDatasetKey.ProductSpAdgroup] = getTableWhitelist(
    [
      ESPReportProductsColumnHeader.Image,
      ESPReportProductsColumnHeader.Name,
      ESPReportProductsColumnHeader.Status,
      ESPReportProductsColumnHeader.InStock,
      ...spProductMetricHeaders, // exception
    ],
    'products'
  )
  whitelist[EDatasetKey.ProductDaAdgroup] = getTableWhitelist(
    [
      EDAReportProductsColumnHeader.Image,
      EDAReportProductsColumnHeader.Name,
      EDAReportProductsColumnHeader.Status,
      EDAReportProductsColumnHeader.InStock,
      ...daProductMetricHeaders, // exception
    ],
    'products'
  )
  whitelist[EDatasetKey.CategorySpAdgroup] = getTableWhitelist(
    [
      ESPReportCategoriesColumnHeader.Name,
      ESPReportCategoriesColumnHeader.Status,
      ESPReportCategoriesColumnHeader.BidPrice,
      ...spMetricHeaders,
    ],
    'categories'
  )
  whitelist[EDatasetKey.CategoryDaAdgroup] = getTableWhitelist(
    [
      EDAReportCategoriesColumnHeader.Name,
      EDAReportCategoriesColumnHeader.Status,
      EDAReportCategoriesColumnHeader.BidPrice,
      ...daMetricHeaders,
    ],
    'categories'
  )
  whitelist[EDatasetKey.KeywordSpAdgroup] = getTableWhitelist(
    [
      ESPReportKeywordsColumnHeader.Name,
      ESPReportKeywordsColumnHeader.Status,
      ESPReportKeywordsColumnHeader.MatchType,
      ESPReportKeywordsColumnHeader.BidPrice,
      ...spKeywordMetricHeaders, // exception
    ],
    'keywords'
  )
  whitelist[EDatasetKey.KeywordDaAdgroup] = getTableWhitelist(
    [
      EDAReportKeywordsColumnHeader.Name,
      EDAReportKeywordsColumnHeader.Status,
      EDAReportKeywordsColumnHeader.MatchType,
      EDAReportKeywordsColumnHeader.BidPrice,
      ...daKeywordMetricHeaders, // exception
    ],
    'keywords'
  )
  whitelist[EDatasetKey.PositionDaAdgroupPagePosition] = getTableWhitelist(
    [
      EDAReportPositionsColumnHeader.Name,
      EDAReportPositionsColumnHeader.Status,
      EDAReportPositionsColumnHeader.BidPrice,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.PositionDaAdgroupCategory] = getTableWhitelist(
    [
      EDAReportPositionsColumnHeader.Name,
      EDAReportPositionsColumnHeader.Status,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.PositionDaAdgroupKeyword] = getTableWhitelist(
    [
      EDAReportPositionsColumnHeader.Name,
      EDAReportPositionsColumnHeader.Status,
      ...daMetricHeaders,
    ],
    'positions'
  )
  whitelist[EDatasetKey.AudienceDaAdgroup] = getTableWhitelist(
    [
      EDAReportAudiencesColumnHeader.Name,
      EDAReportAudiencesColumnHeader.Status,
      EDAReportAudiencesColumnHeader.Positions,
      ...daAudienceMetricHeaders, // exception
    ],
    'audiences'
  )
  whitelist[EDatasetKey.AudienceDoohAdgroup] = getTableWhitelist(
    [
      EDAReportAudiencesColumnHeader.Name,
      EDAReportAudiencesColumnHeader.Status,
      EDAReportAudiencesColumnHeader.Positions,
      ...doohAudienceMetricHeaders, // exception,
    ],
    'audiences'
  )
  whitelist[EDatasetKey.LocationDooh] = getTableWhitelist(
    [
      EDOOHReportLocationsColumnHeader.Name,
      EDOOHReportLocationsColumnHeader.Type,
      EDOOHReportLocationsColumnHeader.AdgroupName,
      ...doohMetricHeaders,
    ],
    'locations'
  )

  if (adGroup.id && key === EDatasetKey.LocationDooh) {
    whitelist[EDatasetKey.LocationDooh] = whitelist[
      EDatasetKey.LocationDooh
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (adGroup.id && key === EDatasetKey.AudienceDa) {
    whitelist[EDatasetKey.AudienceDa] = whitelist[
      EDatasetKey.AudienceDa
    ].filter(
      (column) => column.header !== EDOOHReportLocationsColumnHeader.AdgroupName
    )
  }
  if (campaign.id && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductSp) {
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Status
    )
    whitelist[EDatasetKey.ProductSp] = whitelist[EDatasetKey.ProductSp].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.AvgRank
    )
  }
  if (campaign.id && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Campaigns
    )
  }
  if (adGroup.id && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== EDAReportProductsColumnHeader.Adgroups
    )
  }
  if ((!campaign.id || !adGroup.id) && key === EDatasetKey.ProductDa) {
    whitelist[EDatasetKey.ProductDa] = whitelist[EDatasetKey.ProductDa].filter(
      (column) => column.header !== ESPReportProductsColumnHeader.Status
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignGeneric) {
    whitelist[EDatasetKey.CampaignGeneric] = whitelist[
      EDatasetKey.CampaignGeneric
    ].filter(
      (column) =>
        column.header !== EGenericReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignSp) {
    whitelist[EDatasetKey.CampaignSp] = whitelist[
      EDatasetKey.CampaignSp
    ].filter(
      (column) =>
        column.header !== ESPReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDa) {
    whitelist[EDatasetKey.CampaignDa] = whitelist[
      EDatasetKey.CampaignDa
    ].filter(
      (column) =>
        column.header !== EDAReportCampaignsColumnHeader.AdvertiserName
    )
  }
  if (orgType === EOrgType.Advertiser && key === EDatasetKey.CampaignDooh) {
    whitelist[EDatasetKey.CampaignDooh] = whitelist[
      EDatasetKey.CampaignDooh
    ].filter(
      (column) =>
        column.header !== EDOOHReportCampaignsColumnHeader.AdvertiserName
    )
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    whitelist[key] = whitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(column.header)
    )
  }

  return whitelist[key]
}
