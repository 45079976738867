import { PayloadAction } from '@reduxjs/toolkit'
import { IIsReportingV3 } from '../../../models/interfaces/IIsReportingV3'

const setIsReportingV3 = (
  state: IIsReportingV3,
  action: PayloadAction<{
    IsReportingV3: boolean
  }>
) => {
  state.IsReportingV3 = action.payload.IsReportingV3
}

export default setIsReportingV3
