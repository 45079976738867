import styled from 'styled-components'
import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  Alignment,
  Border,
  Display,
  FontPreset,
  FontStyle,
  Size,
  TActionMixin,
  TAlignmentMixin,
  TBorderMixin,
  TContainerProps,
  TDisplayMixin,
  TFontPresetMixin,
  TFontStyleMixin,
  Truncate,
  TruncatePreset,
  TSizeMixin,
  TTruncateMixin,
} from '../../styles/mixins'
import { StyledDatePicker } from './StyledDatePicker'

const { RangePicker } = StyledDatePicker
export const StyledRangePicker = styled(RangePicker)<TContainerProps>`
  && {
    background: ${(props) => props.background || ''};
    padding: ${(props) => props.padding || 'var(--padding-default)'};
    margin: ${(props) => props.margin || ''};
    ${(props: TDisplayMixin) => Display({ ...props })};
    ${(props: TFontPresetMixin) => FontPreset(props.preset)};
    ${(props: TFontStyleMixin) => FontStyle({ ...props })};

    ${(props: TBorderMixin) =>
      Border({
        ...props,
      })};

    ${(props: TSizeMixin) =>
      Size({
        height: 'var(--button-size-lg)',
        ...props,
      })};

    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    ${(props: TActionMixin) =>
      Action({
        ...props,
        hoverAction: {
          cursor: 'pointer',
        },
      })}

    & .ant-picker > span, svg, input {
      ${() =>
        Action({
          hoverAction: {
            cursor: 'pointer',
          },
          disableAction: {
            cursor: 'not-allowed',
          },
        })}
    }

    ${(props: TTruncateMixin) => Truncate({ ...props })}

    & ::placeholder {
      opacity: 0.5;
      text-transform: capitalize;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
      ${TruncatePreset('100%')};
    }

    &&.ant-picker.ant-picker-disabled {
      ${(props: IThemeProps) =>
        Action({
          cursor: 'not-allowed',

          border: {
            color:
              props.theme.colors?.border?.primary ||
              'var(--border-color-default)',
          },

          backgroundColor:
            props.theme?.colors?.background?.disabled ||
            'var(--background-color-disabled)',
        })}
    }

    & .ant-picker-input > input[disabled] {
      ${(props: IThemeProps) =>
        Action({
          color:
            props.theme.colors?.font?.primary || 'var(--font-color-default)',
          opacity: 0.5,
        })}
    }
  }

  + div {
    .ant-picker-date-range-wrapper {
      min-width: 800px !important;
      .ant-picker-panel-container,
      .ant-picker-panels,
      .ant-picker-date-panel {
        width: 100%;
      }

      tr {
        border-bottom: var(--gutter-1x) solid var(--background-color-default);
      }
    }

    .ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-range-hover-start::after {
      height: 40px;
    }

    .ant-picker-panel-has-range {
      width: 50%;
      max-width: 50%;
      box-sizing: border-box;

      .ant-picker-date-panel {
        min-width: 100%;
      }

      .ant-picker-body table {
        width: 350px;
      }

      .ant-picker-cell-in-view {
        &.ant-picker-cell {
          &-selected,
          &-range-end,
          &-range-start {
            -webkit-text-fill-color: var(--font-color-light);
          }
        }
      }

      .ant-picker-cell {
        width: 40px;
        height: 40px;
        padding: 0;
        opacity: 0.8 !important;
        -webkit-text-fill-color: var(--font-color-placeholder);

        &::before,
        &::after {
          height: 40px !important;
        }

        &-in-view {
          -webkit-text-fill-color: var(--font-color-default);
          opacity: 1 !important;
        }

        &-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 40px;
        }
        &-disabled {
          -webkit-text-fill-color: var(--font-color-placeholder);
        }
      }
    }

    .ant-picker-ranges {
      display: flex;
      gap: var(--gutter-2x);

      .ant-picker-preset .ant-tag {
        font-size: inherit;
        padding: 4px 6px;
      }
    }
  }
`
