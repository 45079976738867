import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { selectCampaignsChartReportingFilters } from '../../../store/reportingFilters'
import { ETimeBucketType } from '../../../models/core'
import { dayjsFormat } from '../../../utils/helpers'

export const useGetFormattedDate = ({ date }: { date: string | number }) => {
  const intl = useIntl()
  const { aggregationType: reduxAggregationType } = useSelector(
    selectCampaignsChartReportingFilters
  )
  const aggregationType = reduxAggregationType || ETimeBucketType.Daily
  if (!date) {
    return ''
  }

  if (aggregationType === ETimeBucketType.Daily) {
    return dayjs(date).format(dayjsFormat)
  }
  if (aggregationType === ETimeBucketType.Weekly) {
    const weekDate = (date as string).split('-') // example 2024-42, result: ['2024', '42']
    const weekString = intl.formatMessage({
      id: `week`,
    })
    return `${weekString} ${weekDate[1]}, ${weekDate[0]}`
  }
  if (aggregationType === ETimeBucketType.Monthly) {
    const monthDate = (date as string).split('-') // example 2024-4, result: ['2024', '4']
    const monthString = intl.formatMessage({
      id: `month-${monthDate[1]}`,
    })
    return `${monthString}, ${monthDate[0]}`
  }
  if (aggregationType === ETimeBucketType.Quarterly) {
    const quaterlyDate = (date as string).split('-') // example 2024-2, result: ['2024', '2']
    const quaterlyShortString = intl.formatMessage({
      id: `quaterly-short`,
    })
    return `${quaterlyShortString}${quaterlyDate[1]}, ${quaterlyDate[0]}`
  }

  // this applies for unsupported ETimeBucketType and ETimeBucketType.Yearly
  return date
}
