import {
  EDAReportAdGroupsColumnHeader,
  EGenericReportCampaignsColumnHeader,
} from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { ESPReportProductsColumnHeader } from '../../../../../models'
import { TGlobalMetrics } from '../../../models'

export const spMetricNames: Array<TGlobalMetrics> = [
  ESPReportProductsColumnHeader.Impressions,
  ESPReportProductsColumnHeader.Clicks,
  ESPReportProductsColumnHeader.Ctr,
  ESPReportProductsColumnHeader.Costs,
  ESPReportProductsColumnHeader.Cpc,
  EDAReportAdGroupsColumnHeader.ShareOfVoice,
  EDAReportAdGroupsColumnHeader.WinRate,
  ESPReportProductsColumnHeader.AvgRank,
  ESPReportProductsColumnHeader.Carts,
  ESPReportProductsColumnHeader.Purchases,
  ESPReportProductsColumnHeader.Revenue,
  ESPReportProductsColumnHeader.Roas,
  EGenericReportCampaignsColumnHeader.NewToBrandPurchases,
  EGenericReportCampaignsColumnHeader.NewToBrandRevenue,
]
