import styled from 'styled-components'
import { Size } from '../../styles'
import { StyledModal } from './StyledModal'

export const StyledModalRight = styled(StyledModal)`
  .modal-dialog {
    margin: 0 0 0 auto;
    max-width: unset;
    width: 640px;

    .modal-content {
      height: 100vh;
      overflow: hidden auto;
      border-radius: 0px;

      .modal-header {
        border: 0;
      }
      .modal-body {
        border: 0;
        margin-bottom: 50px;
      }
      .modal-footer {
        position: fixed;
        border: none;
        z-index: 100;
        height: 100px;
        bottom: 0;
        right: 0;
        padding-top: 0;
        padding-bottom: 0;
        width: 640px;
        background-color: var(--background-color-light);
      }
    }
  }

  && .creative-modal,
  && .fallback-modal {
    ${Size({
      width: '85%',
      maxWidth: '1280px',
      minWidth: '860px',
    })}

    .modal-content {
      overflow: hidden;
      .modal-body {
        overflow: hidden;
        border: 0;
        margin-bottom: 0;
      }
    }
  }

  && .custom-size-ad-data-template {
    ${Size({
      width: '90%',
      maxWidth: '1290px',
      minWidth: '920px',
    })}

    .modal-content {
      overflow: hidden;
      .modal-body {
        overflow: hidden;
        border: 0;
        margin-bottom: 0;
        padding-left: var(--gutter-5x);
      }
    }
  }

  && .custom-size-forecast-dooh-create {
    ${Size({
      width: '690px',
    })}

    .modal-content {
      overflow: hidden;
      .modal-body {
        overflow: hidden;
        border: 0;
        margin-bottom: 100px;
        padding-left: var(--gutter-5x);
        overflow-y: auto;
      }
      .modal-footer {
        ${Size({
          width: '689px',
        })}
      }
    }
  }

  && .product-modal {
    z-index: 1040;
    ${Size({
      width: '75%',
      maxWidth: '1040px',
      minWidth: '840px',
    })}

    & .modal-title {
      padding-left: 0;
    }

    & .modal-footer {
      width: 75%;
      max-width: 1035px;
      min-width: 835px;
    }
  }

  && .custom-size-create-adgroup {
    ${Size({
      width: '75%',
      maxWidth: '1040px',
      minWidth: '840px',
    })}

    & .modal-content .modal-footer {
      width: 74%;
      max-width: 1035px;
      min-width: 835px;
    }
  }
`
