import React from 'react'
import { CSSProperties } from 'styled-components'
import { EParamSortOrder } from '@unfoldrtech/portal-mic'
import {
  StyledTable,
  StyledTableHeaderRow,
  Container,
  StyledTableIconRow,
  StyledTableHeaderCell,
  SVGContainer,
  IconArrowDownSmall,
  Text,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  IconArrowUpSmall,
  TooltipWrapper,
} from '../../Global'
import { IReportFiltersProps } from '../../../models/interfaces'
import { TTranslationKey } from '../../../models/types'
import I18NText from '../../I18NText'
import { TStickyProps } from '../../Global/styles/mixins'
import { sanitizeDataTestId } from '../../../utils/sanitizeDataTestId'
import { TReportTableDataV3 } from '../models/types/report-table-data-v3.type'
import { TGlobalTables } from '../models/types/global-tables.type'

export function ReportTableV3({
  columnNameList,
  columnNameTooltipList,
  columnIdList,
  columnSpanList,
  rowList,

  fixedColumnIndex = 0,
  noDataTranslationKey = 'no.data',

  sortBy,
  sortOrder,

  setSortBy = () => {},
  setSortOrder = () => {},

  top = '0px',
}: {
  fixedColumnIndex?: number
  noDataTranslationKey?: TTranslationKey
  sortBy?: TGlobalTables
  sortOrder?: EParamSortOrder
  setSortBy?: (s: TGlobalTables) => void
  setSortOrder?: (t: EParamSortOrder) => void
} & TReportTableDataV3 &
  IReportFiltersProps &
  Pick<CSSProperties, 'top'>) {
  if (!(columnNameList.length && columnIdList.length && rowList.length)) {
    return (
      <>
        <Container
          textAlign="center"
          preset="h3"
          width="100%"
          padding="var(--padding-default)"
        >
          <I18NText id={noDataTranslationKey} />
        </Container>
      </>
    )
  }

  const getStickyProps = (index: number, zIndex: number = 20): TStickyProps => {
    let stickyProps: TStickyProps = {
      position: 'sticky',
      left: '',
      zIndex: 'auto',
    }
    if (index === fixedColumnIndex) {
      stickyProps = {
        position: 'sticky',
        left: '0px',
        zIndex,
      }
    }
    return stickyProps
  }

  return (
    <StyledTable responsive width="unset" minWidth="100%">
      <StyledTableHeaderRow top={top} zIndex={5}>
        <StyledTableIconRow>
          {columnNameList.map((columnName, index) => {
            const columnId = columnIdList[index] as string
            const colSpan = columnSpanList[index]
            const stickyProps = getStickyProps(index)
            const tooltip = columnNameTooltipList[index]
            return (
              <StyledTableHeaderCell
                {...stickyProps}
                key={columnId}
                colSpan={colSpan}
                onClick={() => {
                  /**
                   * If the same column is clicked, change sort type
                   * else change the sort column
                   */
                  if (sortBy === columnId) {
                    setSortOrder(
                      sortOrder === EParamSortOrder.Asc
                        ? EParamSortOrder.Desc
                        : EParamSortOrder.Asc
                    )
                  } else {
                    setSortBy(columnId)
                    /* The default sorting direction should be desc */
                    setSortOrder(EParamSortOrder.Desc)
                  }
                }}
                data-testid={sanitizeDataTestId(
                  `report-table-header-${columnName}`
                )}
              >
                <Container
                  display="flex"
                  alignItems="center"
                  width="100%"
                  paddingRight={
                    sortBy === columnId ? '' : 'var(--margin-default)'
                  }
                  color={
                    sortBy === columnId ? 'var(--font-color-active)' : undefined
                  }
                >
                  <TooltipWrapper tooltip={tooltip} showTooltip={true}>
                    <Container
                      display="flex"
                      padding="0 var(--margin-default) 0 0"
                    >
                      <Container
                        flex="1"
                        whiteSpace="nowrap"
                        padding="0 var(--gutter-mini) 0 0"
                      >
                        <Text>{columnName}</Text>
                      </Container>
                      {sortBy === columnId &&
                        sortOrder === EParamSortOrder.Asc && (
                          <SVGContainer>
                            <IconArrowUpSmall data-testid="icon-arrow-up-small" />
                          </SVGContainer>
                        )}
                      {sortBy === columnId &&
                        sortOrder === EParamSortOrder.Desc && (
                          <SVGContainer>
                            <IconArrowDownSmall data-testid="icon-arrow-down-small" />
                          </SVGContainer>
                        )}
                    </Container>
                  </TooltipWrapper>
                </Container>
              </StyledTableHeaderCell>
            )
          })}
        </StyledTableIconRow>
      </StyledTableHeaderRow>
      <StyledTableBody>
        {rowList.map((rowData, index) => {
          const rowKey = `${rowData[0]}-${index}`

          return (
            <StyledTableRow
              key={rowKey}
              data-testid={`report-table-row-${index}`}
            >
              {rowData.map((cellData, cellIndex) => {
                const columnId = columnIdList[cellIndex] as string
                const colSpan = columnSpanList[cellIndex]

                const key = `${columnId}-${cellIndex}`
                const stickyProps = getStickyProps(cellIndex, 5)

                return (
                  <StyledTableCell
                    {...stickyProps}
                    key={key}
                    colSpan={colSpan}
                    backgroundColor="var(--background-color-default)"
                    data-testid={`report-table-row-${index}-cell-${cellIndex}`}
                  >
                    {cellData}
                  </StyledTableCell>
                )
              })}
            </StyledTableRow>
          )
        })}
      </StyledTableBody>
    </StyledTable>
  )
}
