import { EGenericReportCampaignsColumnHeader } from '../../../../../models/core'
import { TGlobalMetrics } from '../../../models'

export const genericMetricNames: Array<TGlobalMetrics> = [
  EGenericReportCampaignsColumnHeader.Impressions,
  EGenericReportCampaignsColumnHeader.Clicks,
  EGenericReportCampaignsColumnHeader.Ctr,
  EGenericReportCampaignsColumnHeader.Costs,
  EGenericReportCampaignsColumnHeader.ShareOfVoice,
  EGenericReportCampaignsColumnHeader.WinRate,
  // ESPReportProductsColumnHeader.AvgRank,
  EGenericReportCampaignsColumnHeader.Carts,
  EGenericReportCampaignsColumnHeader.Purchases,
  EGenericReportCampaignsColumnHeader.Revenue,
  EGenericReportCampaignsColumnHeader.Roas,
  EGenericReportCampaignsColumnHeader.NewToBrandPurchases,
  EGenericReportCampaignsColumnHeader.NewToBrandRevenue,
]
