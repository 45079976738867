import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m4.328 6.185 4.666 4.667 4.667-4.667h-2.593V1.52A.519.519 0 0 0 10.55 1H7.439a.519.519 0 0 0-.519.519v4.666H4.328Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.556 11.889v2.074c0 .572-.464 1.037-1.038 1.037H2.037A1.037 1.037 0 0 1 1 13.963v-2.074"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgIconDownload
