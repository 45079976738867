import {
  EEntityType,
  EOrgType,
  EStatus,
  EUserRole,
  IEntity,
} from '@unfoldrtech/portal-mic'
import React, { useContext } from 'react'
import {
  Container,
  CurrentStatusBadge,
  HoverContainer,
  IconArrowDownSmall,
  SVGContainer,
  StyledAntDropdown,
  StyledAntMenu,
  StyledCircle,
  TooltipWrapper,
} from '../../../Global'
import { isUserAuthorised } from '../../../../utils/userAuthorisation'
import {
  AppContext,
  CampaignContext,
  EDeletedStatus,
  TTranslationKey,
} from '../../../../models'
import useUpdateEntityStatus from '../../../../hooks/useUpdateEntityStatus'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import I18NText from '../../../I18NText'

export const TableDataCellStatusCampaignWithUpdate = ({
  dataValue,
  orgType,
  id,
  isDirectDeal,
  isCompleted,
  setErrorUpdateStatus,
  setShowUpdateStatusErrorToast,
}: {
  dataValue: EStatus
  orgType: EOrgType
  id: number
  isDirectDeal: boolean
  isCompleted: boolean
  setErrorUpdateStatus: (error: TTranslationKey) => void
  setShowUpdateStatusErrorToast: (show: boolean) => void
}) => {
  const [appContext] = useContext(AppContext)
  const [currentDataValue, setCurrentDataValue] = React.useState(dataValue)

  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()
  const campaign = useContext(CampaignContext)

  const userRole = appContext.userClaims?.userRole ?? EUserRole.None
  const isMutateNotAllowed = !isUserAuthorised(
    userRole,
    EUserRole.AdvertiserCampaigns
  )
  const isSwitchDisabled = currentDataValue === EStatus.Disabled
  const showSwitch = orgType === EOrgType.Advertiser
  const isDisabled = (isMutateNotAllowed || isSwitchDisabled) && showSwitch
  const isDeleted =
    (currentDataValue as EStatus | EDeletedStatus) === EDeletedStatus.Deleted
  const tooltipKey: TTranslationKey = 'entity.disabled'

  const {
    mutate: updateCampaignStatus,
    isLoading: isUpdateCampaignStatusLoading,
    reset: resetUpdateCampaignStatusMutate,
  } = useUpdateEntityStatus({
    advertiserId,
    retailerId,
  })

  return (
    <>
      <TooltipWrapper
        tooltipKey={tooltipKey}
        showTooltip={isDisabled && !isDeleted}
      >
        <StyledAntDropdown
          overlay={
            <StyledAntMenu
              onClick={({ key }) => {
                const currentStatus = currentDataValue as EStatus
                const updatedStatus = key as EStatus
                setCurrentDataValue(updatedStatus)

                resetUpdateCampaignStatusMutate()

                updateCampaignStatus(
                  {
                    id,
                    entityType: EEntityType.Campaign,
                    entity: {
                      status: updatedStatus,
                      campaignId: campaign.id,
                    } as IEntity,
                  },
                  {
                    onError: (error) => {
                      setCurrentDataValue(currentStatus)

                      setShowUpdateStatusErrorToast(true)
                      setErrorUpdateStatus(
                        error?.response?.data
                          .errorKey as unknown as TTranslationKey
                      )
                    },
                  }
                )
              }}
            >
              <StyledAntMenu.Item key={EStatus.Active}>
                <Container display="flex" alignItems="center">
                  <StyledCircle
                    backgroundColor="var(--badge-color-active)"
                    width="8px"
                    height="8px"
                  />
                  <Container marginLeft="var(--gutter-default)">
                    <I18NText
                      fontFamily="OpenSans"
                      size="var(--font-size-ps)"
                      textTransform="capitalize"
                      id="active"
                      data-testid="table-status-active-option"
                    />
                  </Container>
                </Container>
              </StyledAntMenu.Item>
              <StyledAntMenu.Item key={EStatus.Inactive} danger>
                <Container display="flex" alignItems="center">
                  <StyledCircle
                    backgroundColor="var(--badge-color-inactive)"
                    width="8px"
                    height="8px"
                  />
                  <Container marginLeft="var(--gutter-default)">
                    <I18NText
                      fontFamily="OpenSans"
                      size="var(--font-size-ps)"
                      textTransform="capitalize"
                      id="inactive"
                      data-testid="table-status-inactive-option"
                    />
                  </Container>
                </Container>
              </StyledAntMenu.Item>
            </StyledAntMenu>
          }
          disabled={
            isUpdateCampaignStatusLoading ||
            isDisabled ||
            isDeleted ||
            !showSwitch ||
            isDirectDeal ||
            isCompleted
          }
          overlayStyle={{ zIndex: 1000 }}
          key={Number(Math.random() * 1000).toString()}
        >
          <HoverContainer
            preset="paragraph opensans semibold"
            display="flex"
            alignItems="center"
            height="100%"
            width="100%"
            cursor="pointer"
            onClick={(e) => {
              e.preventDefault()
            }}
            data-testid="report-table-status-dropdown"
          >
            <CurrentStatusBadge status={currentDataValue as EStatus} />
            {!isDisabled &&
              !isDeleted &&
              showSwitch &&
              !isDirectDeal &&
              !isCompleted && (
                <SVGContainer
                  preset="paragraph small"
                  margin="0 0 0 var(--margin-default)"
                  className="hover-items"
                >
                  <IconArrowDownSmall />
                </SVGContainer>
              )}
          </HoverContainer>
        </StyledAntDropdown>
      </TooltipWrapper>
    </>
  )
}
