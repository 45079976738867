import styled, { CSSProperties } from 'styled-components'

interface IconButtonProps extends Pick<CSSProperties, 'opacity'> {
  disabled?: boolean
}

export const IconButton = styled.i<IconButtonProps>`
  cursor: pointer;
  width: var(--icon-size-sm);
  height: auto;
  opacity: ${(props) => props.opacity || 1};

  &:hover {
    opacity: 0.5;
  }

  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
`
/* export const StyledIconButtonStyleguide = styled(ButtonIcon)<{
  color?: string
}>`
  background-color: ${(props) =>
    props.color || styleguide.colors['neutral-default'].value};
  font-family: ${styleguide.text_styles['button-text'].value.font.family};
  font-size: ${styleguide.text_styles['button-text'].value.font.size};
  font-weight: ${styleguide.text_styles['button-text'].value.font.weight};
  font-stretch: ${styleguide.text_styles['button-text'].value.font.stretch};
  line-height: ${styleguide.text_styles['button-text'].value.line_height}px;
  letter-spacing: ${styleguide.text_styles['button-text'].value.letter_spacing};
  alignment: ${styleguide.text_styles['button-text'].value.alignment};
  color: ${styleguide.text_styles['button-text'].value.color};
  border-radius: 0px;
` */
