import {
  TDAAdGroupsPositionsReportChartFilters,
  TDAAdGroupsReportChartFilters,
  TDOOHAdGroupsReportChartFilters,
  TDOOHAdGroupsScreensReportChartFilters,
  TGenericAdGroupsCategoriesReportChartFilters,
  TGenericAdGroupsKeywordsReportChartFilters,
  TGenericAdGroupsReportChartFilters,
  TSPAdGroupsReportChartFilters,
} from '../../../models/types'

type TAdGroupsChartReportingFilters =
  | TGenericAdGroupsReportChartFilters
  | TGenericAdGroupsCategoriesReportChartFilters
  | TGenericAdGroupsKeywordsReportChartFilters
  | TSPAdGroupsReportChartFilters
  | TDAAdGroupsReportChartFilters
  | TDAAdGroupsPositionsReportChartFilters
  | TDOOHAdGroupsReportChartFilters
  | TDOOHAdGroupsScreensReportChartFilters
export const selectAdGroupsChartReportingFilters = ({
  reportingFilters: reportingAdGroupsChartFiltersState,
}: {
  reportingFilters: TAdGroupsChartReportingFilters
}): TAdGroupsChartReportingFilters => reportingAdGroupsChartFiltersState
