import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconExchangeFundsLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#icon-exchange-funds-line_svg__a)">
      <path
        d="M25.833 20.137a10.668 10.668 0 0 0-15.126-13.4L9.384 4.421a13.328 13.328 0 0 1 13.283.032c5.986 3.456 8.28 10.856 5.489 17.027l1.79 1.032-5.554 2.952-.22-6.285 1.661.958ZM6.167 11.863a10.668 10.668 0 0 0 15.126 13.4l1.323 2.315a13.327 13.327 0 0 1-13.283-.031C3.347 24.09 1.053 16.69 3.843 10.52l-1.79-1.03 5.554-2.953.22 6.285-1.662-.958.002-.002Zm11.72 7.907L14.112 16l-3.77 3.77-1.886-1.885 5.657-5.656L17.885 16l3.772-3.77 1.886 1.886-5.658 5.655h.002Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="icon-exchange-funds-line_svg__a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIconExchangeFundsLine
